import React from "react";
import ItemIcon from "./ItemIcon";

const XIcon = () => (
    <svg width="22" height="22" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12.3536 4.35355C12.5488 4.15829 12.5488 3.84171 12.3536 3.64645C12.1583 3.45118 11.8417 3.45118 11.6464 3.64645L8 7.29289L4.35355 3.64645C4.15829 3.45118 3.84171 3.45118 3.64645 3.64645C3.45118 3.84171 3.45118 4.15829 3.64645 4.35355L7.29289 8L3.64645 11.6464C3.45118 11.8417 3.45118 12.1583 3.64645 12.3536C3.84171 12.5488 4.15829 12.5488 4.35355 12.3536L8 8.70711L11.6464 12.3536C11.8417 12.5488 12.1583 12.5488 12.3536 12.3536C12.5488 12.1583 12.5488 11.8417 12.3536 11.6464L8.70711 8L12.3536 4.35355Z"
            fill="#D1CCE0"
        />
    </svg>
);

const ArrowUp = () => (
    <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4.71308 1.3332C5.42168 0.624609 6.57826 0.624609 7.28686 1.3332L11.6335 5.67987C11.8288 5.87513 11.8288 6.19172 11.6335 6.38698C11.4383 6.58224 11.1217 6.58224 10.9264 6.38698L6.57975 2.04031C6.26168 1.72224 5.73826 1.72224 5.42019 2.04031L1.07352 6.38698C0.878262 6.58224 0.561679 6.58224 0.366417 6.38698C0.171155 6.19172 0.171155 5.87513 0.366417 5.67987L4.71308 1.3332Z"
            fill="#A39BBA"
        />
    </svg>
);

const ArrowDown = () => (
    <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0.366417 0.612999C0.561679 0.417737 0.878262 0.417737 1.07352 0.612999L5.42019 4.95967C5.73826 5.27774 6.26168 5.27774 6.57975 4.95967L10.9264 0.612999C11.1217 0.417737 11.4383 0.417737 11.6335 0.612999C11.8288 0.808261 11.8288 1.12484 11.6335 1.32011L7.28686 5.66677C6.57826 6.37537 5.42168 6.37537 4.71308 5.66677L0.366417 1.32011C0.171155 1.12484 0.171155 0.808261 0.366417 0.612999Z"
            fill="#A39BBA"
        />
    </svg>
);

interface Props {
    items: { [key: string]: { [key: string]: any } };
    onDelete: (item: string) => void;
    onChange: (item: string, count: number) => void;
}

const SelectedItemsRow = ({ items, onDelete, onChange }: Props) => {
    return (
        <div className="w-full flex flex-row flex-wrap gap-3 mb-7">
            {Object.keys(items).map((key) => {
                return (
                    <div
                        key={key}
                        // className="rounded-2xl bg-lightpurple flex flex-row items-center px-4 py-2 justify-center border-[1px] border-mediumpurple"
                        className="rounded-[100px] bg-lightpurple flex flex-row p-5 py-2 items-center justify-center border-[1px] border-mediumpurple"
                    >
                        <div className="flex h-full justify-around flex-col items-center mr-3">
                            <button
                                className="bg-transparent p-0"
                                onClick={() => {
                                    onChange(key, items[key].count + 1);
                                }}
                            >
                                <ArrowUp />
                            </button>
                            <button
                                className="bg-transparent p-0"
                                onClick={() => {
                                    onChange(key, items[key].count - 1 === 0 ? 1 : items[key].count - 1);
                                }}
                            >
                                <ArrowDown />
                            </button>
                        </div>
                        <div className="flex flex-row items-center">
                            <p style={{ fontFamily: "minecraftFont" }}>{items[key].count}X</p>
                            <ItemIcon item={items[key].item} size={32} />
                        </div>
                        <div className="h-[90%] w-[1px] bg-mediumpurple opacity-50 ml-2"></div>

                        <button onClick={() => onDelete(key)} className="bg-transparent p-0 ml-3">
                            <XIcon />
                        </button>
                    </div>
                );
            })}
        </div>
    );
};

export default SelectedItemsRow;
