import React from "react";
import ValueChangePill from "./ValueChangePill";

interface Props {
    brightness: number;
    saturation: number;
    onChange: (type: string, value: number) => void;
}

const ImageModifierRow = ({ brightness, saturation, onChange }: Props) => {
    return (
        <div className="w-full flex flex-wrap justify-between gap-3 max-sm:mb-5">
            <div className="flex flex-col gap-2 w-full sm:w-[48%]">
                <div className="text-darkgray">Brightness</div>
                <ValueChangePill value={brightness} onChange={(value) => onChange("brightness", value)} />
            </div>
            <div className="flex flex-col gap-2 w-full sm:w-[48%]">
                <div className="text-darkgray">Saturation</div>
                <ValueChangePill value={saturation} onChange={(value) => onChange("saturation", value)} />
            </div>
        </div>
    );
};

export default ImageModifierRow;
