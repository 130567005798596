import React, { useEffect } from "react";
import ItemIcon from "./ItemIcon";
import { getItem } from "../utils";
import Tooltip from "./Tooltip";

type Props = {
    options: any;
    position: { x: number; y: number };
    itemAssetData: any;
    onChoose: (option: any) => void;
};

const titleText = (text: string) => {
    const words = text.split(" ");
    return words.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
};

const getItemsText = (option: any) => {
    return option.items.map((item: any) => titleText(item.item.name.replace("minecraft:", "").replaceAll("_", " "))).join(", ");
};

const ItemPickerTooltip = ({ options, position, itemAssetData, onChoose }: Props) => {
    return (
        <div
            className="bg-gray-900 rounded-md w-fit flex flex-col absolute z-50"
            style={{
                top: position.y - 20 * 0.25 * 16,
                left: position.x,
            }}
        >
            <div className="flex flex-col gap-1 overflow-y-scroll max-h-60 p-3 bg-purple rounded-md">
                {options.map((option: any) => (
                    <Tooltip content={getItemsText(option)}>
                        <div className="gap-2 cursor-pointer flex flex-row p-0 rounded-md" onClick={() => onChoose(option)}>
                            {option.items.map((item: any) => (
                                <div className="flex flex-col gap-1">
                                    <ItemIcon item={getItem(item.item.name, itemAssetData)} size={30} />
                                    <div className="w-full h-[1px] bg-mediumpurple opacity-50"></div>
                                </div>
                            ))}
                        </div>
                    </Tooltip>
                ))}
            </div>
        </div>
    );
};

export default ItemPickerTooltip;
