interface Params {
    color: string;
    selected: boolean;
    onSelect: (color: string) => void;
}

const ColorOption = ({ color, selected, onSelect }: Params) => {
    return (
        <div className="flex items-center justify-center w-[30px]">
            <div
                className={"w-full rounded-md cursor-pointer aspect-square"}
                style={{
                    backgroundColor: color,
                    width: selected ? "25px" : "30px",
                }}
                onClick={() => {
                    onSelect(color);
                }}
                onMouseDown={(e) => e.preventDefault()}
            ></div>
        </div>
    );
};

export default ColorOption;
