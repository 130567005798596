import React, { useEffect, useRef, useState } from "react";
import block_colors from "./data/block_colors";
import block_ids from "./data/block_ids";
import * as NBT from "nbtify";
import ImageModifierRow from "./components/ImageModifierRow";
import Page from "../../components/Page";

const useDebounce = (value: number, delay: number): number => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => setDebouncedValue(value), delay);
        return () => clearTimeout(handler);
    }, [value, delay]);

    return debouncedValue;
};

const UploadIcon = () => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 22.6666V14.6666L9.33331 17.3333" stroke="#A39BBA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M12 14.6666L14.6667 17.3333" stroke="#A39BBA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path
            d="M29.3334 13.3333V20C29.3334 26.6666 26.6667 29.3333 20 29.3333H12C5.33335 29.3333 2.66669 26.6666 2.66669 20V12C2.66669 5.33329 5.33335 2.66663 12 2.66663H18.6667"
            stroke="#A39BBA"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M29.3334 13.3333H24C20 13.3333 18.6667 12 18.6667 7.99996V2.66663L29.3334 13.3333Z"
            stroke="#A39BBA"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
);

interface blockData {
    [item_id: string]: { name: string; color: string };
}
const App = () => {
    const [blocks, setBlocks] = useState<string[][]>([[]]);
    const previewRef = useRef<HTMLCanvasElement>(null);
    const [originalImage, setOriginalImage] = useState<string | null>(null);
    const uploadRef = useRef<HTMLInputElement>(null);

    // Separate states for slider and debounced values
    const [brightness, setBrightness] = useState<number>(50);
    const [saturation, setSaturation] = useState<number>(50);

    // Debounced values
    const debouncedBrightness = useDebounce(brightness, 300);
    const debouncedSaturation = useDebounce(saturation, 300);

    const getBlockData = (block: string): { name: string; color: string } | null => {
        for (const [item_id, data] of Object.entries(block_colors)) {
            if (item_id === block) {
                return data;
            }
        }
        return null;
    };

    const downloadSchem = async (size = 128) => {
        const NBTData = {
            "Made With": "WiseHosting Map Generator",
            Width: size,
            Height: 1,
            Length: size,
            Materials: "Alpha",
            Blocks: new Int8Array(size * size),
            Data: new Int8Array(size * size),
            x: 0,
            y: 0,
            z: 0,
        };
        const amounts: any = {};
        for (let y = 0; y < blocks.length; y++) {
            for (let x = 0; x < blocks[y].length; x++) {
                const block = blocks[y][x];
                if (!amounts[block]) amounts[block] = 0;
                amounts[block]++;
                const blockId = block_ids[block];
                if (blockId) {
                    NBTData.Blocks.set([blockId.id], y * size + x);
                    NBTData.Data.set([blockId.data], y * size + x);
                }
            }
        }

        // Write to NBT
        const nbt = new NBT.NBTWriter();
        const buffer = nbt.write(NBTData);
        const blob = new Blob([buffer], { type: "application/octet-stream" });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "map.schematic";
        a.click();
    };

    const drawMap = () => {
        const canvas = previewRef.current;
        if (canvas) {
            const ctx = canvas.getContext("2d");
            if (ctx) {
                const fullScale = canvas.clientWidth;
                canvas.width = fullScale;
                canvas.height = fullScale;
                ctx.clearRect(0, 0, fullScale, fullScale);
                const blockSize = fullScale / blocks[0].length;
                const aspect = canvas.clientWidth / canvas.clientHeight;
                const yOffset = (canvas.clientHeight - blockSize * blocks.length * aspect) / 2;
                for (let y = 0; y < blocks.length; y++) {
                    for (let x = 0; x < blocks[y].length; x++) {
                        const block = blocks[y][x];
                        const data = getBlockData(block);
                        if (data) {
                            ctx.fillStyle = data.color;
                            ctx.fillRect(x * blockSize, y * blockSize * aspect + yOffset, blockSize, blockSize * aspect);
                        }
                    }
                }
            }
        }
    };

    useEffect(drawMap, [blocks]);

    function hexToRgb(hex: string): [number, number, number] {
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : [0, 0, 0];
    }

    function getClosestColor(rgbColor: [number, number, number], hexColorList: blockData): { block: string; color: string } {
        let closest = { block: "", color: "" };
        let min_distance = Infinity;
        for (const [block, { color }] of Object.entries(hexColorList)) {
            const [r, g, b] = hexToRgb(color);
            const distance = Math.sqrt(Math.pow(r - rgbColor[0], 2) + Math.pow(g - rgbColor[1], 2) + Math.pow(b - rgbColor[2], 2));
            if (distance < min_distance) {
                min_distance = distance;
                closest = { block, color };
            }
        }
        return closest;
    }

    useEffect(() => {
        const process = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            if (ctx) {
                ctx.imageSmoothingEnabled = false;
                const img = new Image();
                img.src = originalImage ?? "";
                img.onload = () => {
                    canvas.width = img.width;
                    canvas.height = img.height;

                    // Adjust brightness and saturation using debounced values
                    const adjustedBrightness = (debouncedBrightness - 50) * 2 + 100;
                    const adjustedSaturation = (debouncedSaturation - 50) * 2 + 100;

                    // Apply filters
                    ctx.filter = `brightness(${adjustedBrightness}%) saturate(${adjustedSaturation}%)`;

                    // Draw the image with the filter applied
                    ctx.drawImage(img, 0, 0, img.width, img.height);

                    // Convert canvas to base64 and update the image state
                    const newImageDataUrl = canvas.toDataURL("image/jpeg");
                    setTimeout(() => {
                        getBlocks(newImageDataUrl);
                    }, 1);
                };
            }
        };

        requestAnimationFrame(process);
    }, [debouncedBrightness, debouncedSaturation, originalImage]);

    const getBlocks = async (img: HTMLImageElement | string) => {
        if (typeof img === "string") {
            const imgElement = document.createElement("img");
            imgElement.src = img;
            imgElement.onload = () => {
                getBlocks(imgElement);
            };
            return;
        }

        const tmp_blocks: string[][] = [];
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        if (ctx) {
            ctx.imageSmoothingEnabled = false;
            // Make the canvas square with the larger dimension of the image
            const size = Math.max(img.width, img.height);
            canvas.width = size;
            canvas.height = size;

            // Fill the canvas with a transparent background
            ctx.clearRect(0, 0, size, size);

            // Calculate position to center the image
            const offsetX = (size - img.width) / 2;
            const offsetY = (size - img.height) / 2;

            // Draw the image centered on the canvas
            ctx.drawImage(img, offsetX, offsetY);

            // Resize to 128x128
            const resizeCanvas = document.createElement("canvas");
            resizeCanvas.width = 128;
            resizeCanvas.height = 128;
            const resizeCtx = resizeCanvas.getContext("2d");
            if (resizeCtx) {
                resizeCtx.imageSmoothingEnabled = false;
                resizeCtx.drawImage(canvas, 0, 0, size, size, 0, 0, 128, 128);
                const data = resizeCtx.getImageData(0, 0, 128, 128).data;

                let curr_layer = 0;
                for (let i = 0; i < data.length; i += 4) {
                    const rgb = [data[i], data[i + 1], data[i + 2]];
                    const alpha = data[i + 3];

                    const { block } = getClosestColor(rgb as [number, number, number], block_colors);
                    if (!tmp_blocks[curr_layer]) {
                        tmp_blocks[curr_layer] = [];
                    }
                    if (alpha === 0) tmp_blocks[curr_layer].push("0");
                    else tmp_blocks[curr_layer].push(block);
                    if (tmp_blocks[curr_layer].length === 128) {
                        curr_layer++;
                    }
                }
            }
        }
        setBlocks(tmp_blocks);
    };

    const onUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const img = new Image();
                img.onload = () => {
                    setOriginalImage(img.src);
                    getBlocks(img);
                };
                img.src = e.target?.result as string;
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <Page toolName="map_generator">
            <div className="grid grid-cols-2 gap-2">
                {/* image upload container */}
                <div className="w-full flex flex-col gap-6 ">
                    <div className="relative rounded-lg bg-lightpurple border-dashed border-2 border-mediumpurple sm:h-full max-sm:h-[300px]">
                        <input
                            ref={uploadRef}
                            type="file"
                            className="absolute w-full h-full opacity-0 cursor-pointer"
                            accept="image/*"
                            onChange={onUpload}
                        />
                        <div className="absolute inset-0 flex justify-center items-center flex-col w-full gap-3">
                            <UploadIcon />
                            <div className="flex justify-center items-center flex-col">
                                <p className="text-white text-lg">Upload or drag & drop file</p>
                                <p className="text-lightergray text-sm">Supports: JPEG, JPG, PNG</p>
                            </div>
                            <button className="bg-lightblue text-black font-semibold mt-2" onClick={() => uploadRef.current?.click()}>
                                Upload File
                            </button>
                        </div>
                    </div>

                    <ImageModifierRow
                        brightness={brightness}
                        saturation={saturation}
                        onChange={(type: string, value: number) => {
                            if (type === "brightness") setBrightness(value);
                            if (type === "saturation") setSaturation(value);
                        }}
                    />
                </div>
                {/* canvas */}
                <div className="flex flex-col gap-2">
                    <div className="w-full rounded-lg aspect-square bg-transparent border-dashed border-2 border-mediumpurple flex justify-center items-center">
                        <canvas ref={previewRef} className="object-contain max-w-full max-h-full" style={{ imageRendering: "pixelated" }} />
                    </div>
                </div>
            </div>
            <div className="flex justify-center gap-2 mt-10">
                <button 
                    className="border-lightgray border-[1px] font-semibold w-full bg-transparent text-lightgray"
                    onClick={() => {
                        setBlocks([[]]);
                        setOriginalImage(null);
                    }}
                >
                    Clear and re-upload
                </button>
                <button
                    className="bg-lightblue text-black font-semibold w-full"
                    onClick={() => {
                        downloadSchem(128);
                    }}
                >
                    Download
                </button>
            </div>
        </Page>
    );
};

export default App;
