import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import "twin.macro";
import ItemImage from "./ItemImage";
import Tooltip from "./Tooltip";
import Slider from "./Slider";

const Backdrop = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(8px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
`;

const ModalContainer = styled.div`
    background: #20193a;

    max-width: 500px;
    width: 80%;

    padding: 20px;
    border-radius: 10px;

    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
`;

const CloseButton = styled.button`
    background: none;
    border: none;
    font-size: 1.5rem;
    position: absolute;
    top: -50px;
    right: -50px;
    cursor: pointer;

    &:active {
        outline: none;
    }

    &:focus {
        outline: none;
    }
`;

interface Props {
    setClickedSlot: React.Dispatch<React.SetStateAction<number | null>>;
    onSelected: (item: Item, amount: number) => void;
    items: Item[];
    visible: boolean;
    setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    showCount: boolean;
}

interface Item {
    name: string;
    id: string;
    offsetX: number;
    offsetY: number;
}

const MaterialSelect = ({ setClickedSlot, onSelected, items, visible, setVisible, showCount }: Props) => {
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [mode, setMode] = useState<number>(1);
    const [selectedItem, setSelectedItem] = useState<Item | null>(null);
    const [amount, setAmount] = useState<number>(1);
    const backdropRef = useRef<HTMLDivElement>(null);
    const mouseDownOnBackdrop = useRef(false);

    const handleBackdropMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
        if (e.target === backdropRef.current) {
            mouseDownOnBackdrop.current = true;
        }
    };

    const handleBackdropMouseUp = (e: React.MouseEvent<HTMLDivElement>) => {
        if (e.target === backdropRef.current && mouseDownOnBackdrop.current) {
            setVisible(false);
            setSearchTerm("");
        }
        mouseDownOnBackdrop.current = false;
    };

    if (!visible) return null;

    return (
        <Backdrop ref={backdropRef} onMouseDown={handleBackdropMouseDown} onMouseUp={handleBackdropMouseUp}>
            <ModalContainer onClick={(e) => e.stopPropagation()}>
                <CloseButton
                    onClick={() => {
                        setVisible(false);
                        setSearchTerm("");
                    }}
                >
                    &times;
                </CloseButton>

                {mode === 1 ? (
                    <>
                        <input
                            type="text"
                            className="w-full rounded-md p-2 outline-none mb-3 bg-lightpurple"
                            placeholder="Search..."
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <div className="flex flex-row flex-wrap overflow-y-scroll max-h-[500px]">
                            {items.map((item, index) => {
                                return (
                                    item.name.toLowerCase().includes(searchTerm.toLowerCase()) && (
                                        <Tooltip content={item.name}>
                                            <div
                                                key={index}
                                                className="w-[32px] h-[32px] m-3 cursor-pointer"
                                                onClick={() => {
                                                    if (!showCount) {
                                                        onSelected(item, 1);
                                                        setVisible(false);
                                                        setSearchTerm("");
                                                        setClickedSlot(null);
                                                        return;
                                                    }
                                                    setMode(2);
                                                    setSelectedItem(item);
                                                }}
                                            >
                                                <ItemImage item={item} />
                                            </div>
                                        </Tooltip>
                                    )
                                );
                            })}
                        </div>
                    </>
                ) : (
                    <div className="flex flex-col gap-2">
                        {/* TODO: have values depends on stacksize */}
                        <Slider value={amount} values={Array.from({ length: 64 }, (_, i) => i + 1)} onChange={setAmount} label="Amount" />
                        <button
                            onClick={() => {
                                onSelected(selectedItem!, amount);
                                setMode(1);
                                setSelectedItem(null);
                                setAmount(1);
                                setVisible(false);
                            }}
                            className="bg-lightblue text-black font-semibold"
                        >
                            Done
                        </button>
                    </div>
                )}
            </ModalContainer>
        </Backdrop>
    );
};

export default MaterialSelect;
