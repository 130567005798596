import React, { useState } from "react";
import ItemImage from "./ItemImage";

interface Item {
    name: string;
    id: string;
    offsetX: number;
    offsetY: number;
}
interface Material {
    item: Item;
    amount: number;
}

interface Props {
    slotClickCallback: (slotIndex: number) => void;
    slotData: Material[];
}

const CraftingTable = ({ slotClickCallback, slotData }: Props) => {
    const [currentSlotHover, setCurrentSlotHover] = useState<number | null>(null);

    return (
        <div className="p-10 border border-lightpurple flex justify-between items-center">
            <div className="grid grid-cols-3 gap-2 w-1/2">
                {Array.from({ length: 9 }).map((_, index) => (
                    <div
                        key={index}
                        className={`border-[1px] border-mediumpurple aspect-square rounded-lg flex items-center justify-center cursor-pointer`}
                        onMouseEnter={() => setCurrentSlotHover(index)}
                        onMouseLeave={() => setCurrentSlotHover(null)}
                        onClick={() => slotClickCallback(index)}
                        style={{
                            backgroundColor: currentSlotHover === index ? "#453e63" : "#342d4e",
                        }}
                    >
                        {slotData[index] !== undefined && <ItemImage item={slotData[index].item} amount={slotData[index].amount} size={40} />}
                    </div>
                ))}
            </div>

            <svg width="28" height="24" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0.667318 10.333L0.667318 13.6663L20.6673 13.6663L20.6673 16.9997L24.0007 16.9997L24.0007 13.6663L27.334 13.6663L27.334 10.333L24.0007 10.333L24.0007 6.99967L20.6673 6.99967L20.6673 10.333L0.667318 10.333ZM17.334 3.66634L20.6673 3.66634L20.6673 6.99967L17.334 6.99967L17.334 3.66634ZM17.334 3.66634L14.0007 3.66634L14.0007 0.333007L17.334 0.333007L17.334 3.66634ZM17.334 20.333L20.6673 20.333L20.6673 16.9997L17.334 16.9997L17.334 20.333ZM17.334 20.333L14.0007 20.333L14.0007 23.6663L17.334 23.6663L17.334 20.333Z"
                    fill="white"
                />
            </svg>

            <div
                key={9}
                className="border-[1px] border-mediumpurple aspect-square rounded-lg flex items-center justify-center w-20 cursor-pointer"
                style={{
                    backgroundColor: currentSlotHover === 9 ? "#453e63" : "#342d4e",
                }}
                onClick={() => slotClickCallback(9)}
                onMouseEnter={() => setCurrentSlotHover(9)}
                onMouseLeave={() => setCurrentSlotHover(null)}
            >
                {slotData[9] !== undefined && <ItemImage item={slotData[9].item} amount={slotData[9].amount} size={50} />}
            </div>
        </div>
    );
};

export default CraftingTable;

{
    /* <ItemImage item={slotData[slot.index].item} size={slot.width * 0.8} amount={slotData[slot.index].amount} /> */
}
// slotClickCallback(slot.index)
