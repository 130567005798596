import React from "react";
import styled from "styled-components";

const Bullet = () => (
    <svg viewBox="0 0 6 7" fill="none" xmlns="http://www.w3.org/2000/svg" className="mt-1 w-[7px] h-[7px] flex-shrink-0">
        <path d="M4.5 1.75V0H1.5V1.75H0V5.25H1.5V7H4.5V5.25H6V1.75H4.5Z" fill="#A39BBA" />
    </svg>
);

interface InputFieldProps {
    label: string;
    description: string;
    onChange: (value: string) => void;
    value: string;
}

const StyledInputField = styled.div`
    display: flex;
    flex-direction: column;
    padding: 15px;
    border-radius: 8px;
    width: 100%;
`;

const Label = styled.label`
    color: #ffffff;
    font-size: 16px;
    margin-bottom: 8px;
`;

const Description = styled.p`
    color: #6f678d;
    font-size: 14px;
    margin-bottom: 12px;
`;

const Input = styled.input`
    background-color: #342d4e;
    color: #ffffff;
    border-radius: 4px;
    padding: 12px;
    font-size: 16px;
    width: 100%;

    &:focus {
        outline: none;
        border-color: #0077ff;
    }
`;

const InputField: React.FC<InputFieldProps> = ({ label, description, onChange, value }) => {
    return (
        <StyledInputField>
            <Label>{label}</Label>

            <Input type="text" onChange={(e) => onChange(e.target.value)} value={value} />
            <div className="flex flex-row gap-2 mt-2">
                <Bullet />
                <Description>{description}</Description>
            </div>
        </StyledInputField>
    );
};

export default InputField;
