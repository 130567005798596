import React, { useState, useEffect } from "react";

interface Params {
    icon?: React.FC;
    text: string;
    action: () => void;
    actionText: string;
    filled?: boolean;
}

const AnimatedButton = ({ icon: Icon, text, action, actionText, filled }: Params) => {
    const [showActionText, setShowActionText] = useState(false);

    useEffect(() => {
        let timer: NodeJS.Timeout;
        if (showActionText) {
            timer = setTimeout(() => setShowActionText(false), 600);
        }
        return () => clearTimeout(timer);
    }, [showActionText]);

    const handleClick = () => {
        action();
        setShowActionText(true);
    };

    return (
        <div
            className={
                "border-lightgray border-[1px] flex items-center gap-2 cursor-pointer rounded-[10px] px-4 py-2 justify-center relative overflow-hidden" +
                (showActionText ? "bg-purple-900" : "") +
                (filled ? " bg-[#00B8FF]" : "")
            }
            style={{ transition: "background-color 0.3s ease" }}
            onClick={handleClick}
        >
            {Icon && <div className={(filled ? "text-black " : "") + (showActionText ? "opacity-0" : "")}> <Icon /></div>}
            <span className={"text-sm font-semibold " + (filled ? " text-black " : "text-lightgray") + (showActionText ? "opacity-0" : "")}>{text}</span>
            {showActionText && (
                <div
                    className={
                        "absolute inset-0 flex items-center justify-center bg-opacity-90 text-lightgray text-sm bg-purple-950 rounded-sm " +
                        (filled ? "!text-black" : "")
                    }
                    style={{
                        animation: "fadeInOut 0.7s ease-in-out",
                    }}
                >
                    {actionText}
                </div>
            )}
            <style>{`
                @keyframes fadeInOut {
                    0% {
                        opacity: 0;
                    }
                    50% {
                        opacity: 1;
                    }
                    60% {
                        opacity: 1;
                    }
                    100% {
                        opacity: 0;
                    }
                }
            `}</style>
        </div>
    );
};

export default AnimatedButton;
