import React from "react";

interface Props {
    color1: string;
    color2: string;
    onSelect: ({ color1, color2 }: any) => void;
    isSelected: boolean;
}

const ContrastPill = ({ color1, color2, onSelect, isSelected }: Props) => {
    return (
        <div
            className={"flex cursor-pointer rounded-md flex-row" + (isSelected ? " border-2 border-[#136fa4]" : "")}
            onClick={() => onSelect({ a: color1, b: color2 })}
        >
            <div className="aspect-square rounded-l-md w-10" style={{ backgroundColor: color1 }}></div>
            <div className="aspect-square rounded-r-md w-10" style={{ backgroundColor: color2 }}></div>
        </div>
    );
};

export default ContrastPill;
