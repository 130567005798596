import React from "react";
import NumberInput from "./NumberInput";

const CoordinateInput = ({ label, onChange, value }: { label: string; onChange: (val: string) => any; value: number }) => {
    return (
        <div className={"p-1 bg-lightpurple rounded-md flex flex-row items-center sm:mr-3 w-full"}>
            <div className="h-[40px] aspect-square bg-purple font-semibold text-lightblue rounded-md flex justify-center items-center">
                {label}
            </div>
            <NumberInput onChange={(v) => onChange(v.toString())} value={value} />
        </div>
    );
};

export default CoordinateInput;
