import React, { useEffect } from "react";

const UpIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-4">
        <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
    </svg>
);

const DownIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-4">
        <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
    </svg>
);

type Props = {
    onChange: (v: number) => any;
    value: number;
    allowMinus?: boolean;
};

const NumberInput = ({ onChange, value, allowMinus = true }: Props) => {
    const inputRef = React.useRef<HTMLInputElement>(null);
    const [isHolding, setIsHolding] = React.useState(false);
    const [addValue, setAddValue] = React.useState(0);
    const [cooldownTimeout, setCooldownTimeout] = React.useState<any>(null);

    const sanitizeValue = (rawValue: string): number => {
        const parsedValue = parseInt(rawValue);
        if (isNaN(parsedValue)) return 0;
        if (!allowMinus && parsedValue < 0) return 0;
        return parsedValue;
    };

    useEffect(() => {
        inputRef.current!.value = value.toString();
    }, [value]);

    useEffect(() => {
        if (!isHolding) {
            clearInterval(cooldownTimeout);
            return;
        }
        let interval: any = null;

        const hold = async () => {
            interval = setInterval(() => {
                const v = sanitizeValue((parseInt(inputRef.current?.value ?? "0") + addValue).toString());
                inputRef.current!.value = v.toString();
                onChange(v);
            }, 100);
        };

        setCooldownTimeout(setTimeout(hold, 500));
        return () => {
            clearInterval(interval);
            clearTimeout(cooldownTimeout);
        };
    }, [isHolding]);

    return (
        <div className="w-full relative">
            <input
                defaultValue="0"
                type="text"
                ref={inputRef}
                onChange={() => {
                    if (isNaN(parseInt(inputRef.current?.value ?? "0"))) return;
                    onChange(parseInt(inputRef.current?.value ?? "0"));
                }}
                onBlur={() => {
                    console.log(inputRef.current?.value);
                    const sanitized = sanitizeValue(inputRef.current?.value ?? "0");
                    inputRef.current!.value = sanitized.toString();
                    onChange(sanitized);
                }}
                className="rounded-md w-full px-5 py-2 bg-[#342D4E] [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none outline-none"
            />

            <div className="flex flex-col absolute right-0 top-0 z-10 h-full justify-center">
                <button
                    onClick={() => {
                        const v = sanitizeValue((parseInt(inputRef.current?.value ?? "0") + 1).toString());
                        inputRef.current!.value = v.toString();
                        onChange(v);
                    }}
                    onPointerDown={(e) => {
                        if (e.button !== 0) return;
                        setIsHolding(true);
                        setAddValue(1);
                    }}
                    onPointerUp={() => {
                        setIsHolding(false);
                    }}
                    className="w-fit bg-transparent py-0 px-2"
                >
                    <UpIcon />
                </button>
                <button
                    onClick={() => {
                        const v = sanitizeValue((parseInt(inputRef.current?.value ?? "0") - 1).toString());
                        inputRef.current!.value = v.toString();
                        onChange(v);
                    }}
                    onPointerDown={(e) => {
                        if (e.button !== 0) return;
                        setIsHolding(true);
                        setAddValue(-1);
                    }}
                    onPointerUp={() => {
                        setIsHolding(false);
                    }}
                    className="w-fit bg-transparent py-0 px-2"
                >
                    <DownIcon />
                </button>
            </div>
        </div>
    );
};

export default NumberInput;
