import { Edge, Node } from "@xyflow/react";
import { Item } from "./components/ItemIcon";
import ELK, { ElkNode } from "elkjs";

// const multiplier_cache: { [key: string]: number } = {};

// Define the structure for base ingredients
interface BaseIngredients {
    path_data: number[][];
    item_data: { [key: string]: number };
}

// Define the structure for a node in the recipe tree
export interface TreeNode {
    name: string;
    amount: number;
    type: "craft" | "smelt" | "obtain";
    paths: TreeNode[][];
    base_item_paths?: BaseIngredients[];
}

export interface TreeNode {
    name: string;
    amount: number;
    type: "craft" | "smelt" | "obtain";
    paths: TreeNode[][];
    base_item_paths?: BaseIngredients[];
}

// Get the current full path of viewd tree, then go through each sub path in pathData and find the path that is prefixed with our item path
// Then after we know the sub path index, we look for all pathDatas that match our pathData exactly but the path in our saved index starts with the item path
const getPathsAt = (
    tree: TreeNode,
    path: number[],
    usedItems: string[],
    currItem: {
        id: string;
        amount: number;
        action: string;
    }
): any => {
    let currentNode: TreeNode | TreeNode[] = tree;
    for (const index of path) {
        if (Array.isArray(currentNode)) {
            currentNode = currentNode[index];
        } else {
            currentNode = currentNode.paths[index];
        }
    }

    const paths = (currentNode as TreeNode).paths;
    const itemOptions: { item: TreeNode; index: number }[][] = [];
    for (const p of paths) {
        const option: { item: TreeNode; index: number }[] = [];
        let pind = 0;
        for (const item of p) {
            if (!usedItems.includes(item.name) && item.name + item.amount + item.type !== currItem.id + currItem.amount + currItem.action)
                option.push({
                    item: item,
                    index: pind,
                });
            pind++;
        }
        if (option.length > 0) itemOptions.push(option);
    }
    return itemOptions;
};

const doesPathMatchPrefix = (path: number[], prefix: number[]): boolean => {
    for (let i = 0; i < prefix.length; i++) {
        if (path[i] !== prefix[i]) {
            return false;
        }
    }
    return true;
};

const getItemListFromPath = (tree: TreeNode, path: number[]): string[] => {
    let currentNode: TreeNode | TreeNode[] = tree;
    const items = [];
    for (const index of path) {
        if (Array.isArray(currentNode)) {
            currentNode = currentNode[index];
        } else {
            items.push(currentNode.name);
            currentNode = currentNode.paths[index];
        }
    }
    if (!Array.isArray(currentNode)) items.push(currentNode.name);
    return items;
};

const findMatchingPathPrefix = (tree: TreeNode, pathPrefix: { [key: string]: number[] }, pathData: number[][], originalPrefix: number[]): any => {
    const currEditingSubpath: number[] = [];
    for (let i = 0; i < pathData.length; i++) {
        if (doesPathMatchPrefix(pathData[i], originalPrefix)) {
            currEditingSubpath.push(i);
        }
    }

    const oldPathData = JSON.parse(JSON.stringify(pathData.filter((_, index) => !currEditingSubpath.includes(index))));
    const sortedOldPathData = [];
    for (const path of oldPathData) sortedOldPathData.push(JSON.stringify(getItemListFromPath(tree, path)));
    sortedOldPathData.sort();
    const serializedSortedOldPathData = JSON.stringify(sortedOldPathData);

    // console.log("Current path: ", serializedSortedOldPathData)

    for (const baseItem of tree.base_item_paths!) {
        if (baseItem.path_data.length !== pathData.length) continue;
        if (JSON.stringify(baseItem.path_data) === JSON.stringify(pathData)) continue;

        const foundCandidatesIndices: number[] = [];
        for (const item in pathPrefix) {
            for (let i = 0; i < baseItem.path_data.length; i++) {
                if (doesPathMatchPrefix(baseItem.path_data[i], pathPrefix[item])) {
                    foundCandidatesIndices.push(i);
                }
            }
        }

        if (foundCandidatesIndices.length !== Object.keys(pathPrefix).length) continue;
        const newPathData = JSON.parse(JSON.stringify(baseItem.path_data.filter((_, index) => !foundCandidatesIndices.includes(index))));
        let sortedNewPathData = [];
        for (const path of newPathData) sortedNewPathData.push(JSON.stringify(getItemListFromPath(tree, path)));
        sortedNewPathData = sortedNewPathData.sort();
        // console.log("Candidate", JSON.stringify(sortedNewPathData), serializedSortedOldPathData, baseItem);

        if (JSON.stringify(sortedNewPathData) === serializedSortedOldPathData) {
            return baseItem.path_data;
        }
    }
};

const shallowMatchPath = (tree: TreeNode, pathPrefix: { [key: string]: number[] }, pathData: number[][]): any => {
    for (const baseItem of tree.base_item_paths!) {
        let matchCounter = 0;
        const usedPaths = new Set();
        for (const item in pathPrefix) {
            for (const path of baseItem.path_data) {
                const serializedPath = JSON.stringify(path);
                if (!usedPaths.has(serializedPath) && doesPathMatchPrefix(path, pathPrefix[item])) {
                    usedPaths.add(serializedPath);
                    matchCounter++;
                }
            }
        }

        if (matchCounter >= Object.keys(pathPrefix).length) {
            return baseItem.path_data;
        }
    }
};

const getLayerItemsAt = (tree: TreeNode, path: number[]): string[] => {
    let currentNode: TreeNode | TreeNode[] = tree;
    for (const index of path) {
        if (Array.isArray(currentNode)) {
            currentNode = currentNode[index];
        } else {
            currentNode = currentNode.paths[index];
        }
    }

    if (!Array.isArray(currentNode)) return [];
    const items = [];
    for (const item of currentNode) {
        items.push(item.name + item.amount + item.type);
    }
    return items;
};

export const getItemOptions = (
    fullTree: TreeNode,
    path: number[],
    pathData: number[][],
    editingItemId: { id: string; amount: number; action: string }
) => {
    if (path.length < 2) {
        return [];
    }

    const itemIndex = path.pop();
    const currLayerItems = getLayerItemsAt(fullTree, path).filter(
        (item) => item !== "minecraft:" + editingItemId.id + editingItemId.amount + editingItemId.action
    );

    // currLayerItems.push("minecraft:" + editingItemId);
    if (itemIndex === undefined) return;
    const originalItemPrefix = [...path, itemIndex];
    path.pop();

    /**
     * Plan for getPathsAt, rn we find the correct path and get the item index.
     * Instead we need to get all items in current path, then give them to getPathsAt, then remove all of them from each path found
     * and return the new paths.
     */

    let itemOptions: ({ item: TreeNode; index: number }[] | null)[] = getPathsAt(fullTree, path, currLayerItems, editingItemId);
    itemOptions = itemOptions.map((option) => {
        const optionItems = option!.map((item) => item.item.name + item.item.amount + item.item.type);
        if (
            optionItems.length === currLayerItems.length + 1 &&
            !optionItems.includes("minecraft:" + editingItemId.id + editingItemId.amount + editingItemId.action)
        ) {
            return option;
        }
        if (optionItems.length !== currLayerItems.length + 1) {
            return option;
        }
        return null;
    });
    let pathInd = -1;
    const newData = [] as any;
    const foundItems = new Set();
    for (const option of itemOptions) {
        pathInd++;
        if (!option) continue;
        const optionKey = option.reduce((acc, item) => acc + item.item.name + item.item.amount + item.item.type, "");
        if (!option.length) continue;
        if (foundItems.has(optionKey)) continue;

        // Get the prefixes we need to look for in path datas
        const lookForPrefixes: { [key: string]: number[] } = {};
        for (const item of option) {
            lookForPrefixes[item.item.name] = [...path, pathInd, item.index];
        }
        // console.log("--------------------------")
        // Set paths for each item to [], since no need to store the extra data
        for (let i = 0; i < option.length; i++) {
            option[i].item = { ...option[i].item, paths: [] };
        }

        let newPathData = findMatchingPathPrefix(fullTree, lookForPrefixes, pathData, originalItemPrefix);
        if (!newPathData) {
            newPathData = shallowMatchPath(fullTree, lookForPrefixes, pathData);
        }
        if (!newPathData) {
            newPathData = pathData;
        }
        newData.push({ items: option, pathData: newPathData });
    }

    return newData;
};

export const getMaxBaseItemsAmount = (tree: TreeNode): number => {
    let maxAmount = 0;
    for (const baseData of tree.base_item_paths!) {
        maxAmount = Math.max(maxAmount, Object.keys(baseData.item_data).length);
    }
    return maxAmount;
};

export const getTrees = async (items: { [key: string]: number }): Promise<{ [key: string]: TreeNode } | null> => {
    const trees: { [key: string]: TreeNode } = {};
    for (let item in items) {
        item = item.replace("minecraft:", "");
        try {
            const res = await fetch(
                `https://raw.githubusercontent.com/K9Developer/MinecraftData/main/recipe_trees/${item.replace("minecraft:", "")}item.json`
            );
            const data = await res.json();
            // trees[item] = modifyAmounts(data, items, item);
            trees[item] = data;
        } catch (e) {
            console.error(e);
            return null;
        }
    }
    return trees;
};

const getJumpAmount = (createItem: string, ingredients: string[], craftData: { [item: string]: any }) => {
    // console.log(createItem, ingredients, craftData)
    const possiblePatterns = craftData[createItem];
    for (const pattern of possiblePatterns) {
        let matches = 0;
        for (const ingredient of ingredients) {
            if (pattern["pattern"].includes(ingredient)) matches++;
        }
        if (matches === ingredients.length) {
            return pattern["amount"];
        }
    }
};

const changeAmounts = (
    treePath: TreeNode,
    baseItems: { pathData: number[]; [item: string]: number | number[] }[],
    craftData: { [item: string]: any },
    currPath: number[] = []
) => {
    if (treePath.paths.length === 0) {
        const backupAmount = treePath.amount;
        for (const baseItem of baseItems) {
            if (JSON.stringify(baseItem.pathData) === JSON.stringify(currPath)) {
                treePath.amount = baseItem[treePath.name] as number;
                break;
            }
        }

        return [treePath.amount, backupAmount];
    }

    const backupAmount = treePath.amount;
    let multiplier = Infinity;
    const ings = [];
    let jumpSize = -1;
    for (let pathIndex = 0; pathIndex < treePath.paths.length; pathIndex++) {
        if (!treePath.paths[pathIndex]) {
            continue;
        }

        for (let elementIndex = 0; elementIndex < treePath.paths[pathIndex].length; elementIndex++) {
            const [newAmount, oldAmount] = changeAmounts(treePath.paths[pathIndex][elementIndex], baseItems, craftData, [
                ...currPath,
                pathIndex,
                elementIndex,
            ]);
            multiplier = Math.min(multiplier, newAmount / oldAmount);
            ings.push(treePath.paths[pathIndex][elementIndex].name);
        }

        if (jumpSize === -1) {
            jumpSize = getJumpAmount(treePath.name, ings, craftData);
        }
    }
    treePath.amount = Math.floor((treePath.amount * multiplier) / jumpSize) * jumpSize;

    return [treePath.amount, backupAmount];
};

export const getPathIndex = (path: number[][], tree: TreeNode): number => {
    let ind = 0;
    for (const pathData of tree.base_item_paths!) {
        if (JSON.stringify(pathData.path_data) === JSON.stringify(path)) {
            return ind;
        }
        ind++;
    }
    return -1;
};

const getAmountMultiplier = (treePath: TreeNode, baseItems: { [key: string]: number }, amount: number): number => {
    let chosenMultiplier = 1;
    multiplier_loop: for (let multiplier = 1; multiplier <= amount * 29; multiplier += 1) {
        for (const baseItem in baseItems) {
            const tmpAmount = baseItems[baseItem] * multiplier;
            let wouldBeResultAmount = treePath.amount * multiplier;
            if (tmpAmount % 1 !== 0 || wouldBeResultAmount < amount) {
                continue multiplier_loop;
            }
        }

        chosenMultiplier = multiplier;
        break;
    }

    // multiplier_cache[treePath.name+"|"+amount] = chosenMultiplier;
    return chosenMultiplier;
};

export const getSplitBaseItems = (tree: TreeNode) => {
    // traverse the tree and get all the nodes that have path.length === 0
    const baseItems: { [key: string]: number | number[] }[] = [];
    const traverse = (node: TreeNode, currPath: number[] = []) => {
        if (node.paths.length === 0) {
            baseItems.push({ [node.name]: node.amount, pathData: currPath });
        } else {
            let pathIndex = 0;
            for (const path of node.paths) {
                if (!path) {
                    pathIndex++;
                    continue;
                }
                let elementIndex = 0;
                for (const item of path) {
                    traverse(item, [...currPath, pathIndex, elementIndex]);
                    elementIndex++;
                }
                pathIndex++;
            }
        }
    };
    traverse(tree);
    return baseItems;
};

export const getNewBaseItemsByAmount = (
    treePath: TreeNode,
    baseItems: { [key: string]: number }[],
    amount: number,
    craftData: any
): { [key: string]: number }[] => {
    const baseItemAmounts = [...baseItems];
    let itemInd = 0;
    for (let baseItemData of baseItemAmounts) {
        const baseItem = { [Object.keys(baseItemData)[0]]: baseItemData[Object.keys(baseItemData)[0]] } as { [key: string]: number };
        for (let targetBaseAmount = 1; targetBaseAmount <= amount * 30; targetBaseAmount++) {
            const wouldBeResult = (treePath.amount / baseItem[Object.keys(baseItem)[0]]) * targetBaseAmount;

            if (wouldBeResult >= amount) {
                baseItemAmounts[itemInd][Object.keys(baseItem)[0]] = targetBaseAmount;
                break;
            }
        }
        itemInd++;
    }
    return baseItemAmounts;
};

const updatePathToAmount = (treePath: TreeNode, amount: number, craftData: any) => {
    if (treePath.paths.length === 0) {
        treePath.amount = amount;
        return;
    }

    for (let pathIndex = 0; pathIndex < treePath.paths.length; pathIndex++) {
        if (!treePath.paths[pathIndex]) {
            continue;
        }

        const jumpSize = getJumpAmount(
            treePath.name,
            treePath.paths[pathIndex].map((e) => e.name),
            craftData
        );
        amount = Math.ceil(amount / jumpSize) * jumpSize;

        for (let elementIndex = 0; elementIndex < treePath.paths[pathIndex].length; elementIndex++) {
            const newAmount = amount / (treePath.amount / treePath.paths[pathIndex][elementIndex].amount);
            updatePathToAmount(treePath.paths[pathIndex][elementIndex], newAmount, craftData);
        }
    }

    treePath.amount = amount;
};

const fixAmounts = (treePath: TreeNode, amount: number, craftData: any): TreeNode => {
    updatePathToAmount(treePath, amount, craftData);
    return treePath;
};

// Remove null from paths, and from elements in paths
const cleanPathTree = (tree: TreeNode): void => {
    if (tree.paths.length === 0) {
        return;
    }

    let pathIndex = 0;
    for (const path of tree.paths) {
        if (!path || path.length === 0) {
            tree.paths.splice(pathIndex, 1);
            continue;
        }

        let elementIndex = 0;
        for (const element of path) {
            if (!element) {
                tree.paths[pathIndex].splice(elementIndex, 1);
                continue;
            }
            cleanPathTree(element);
            elementIndex++;
        }

        pathIndex++;
    }
};

export const getPath = (tree: TreeNode, path_data: number[][], amount: number, craftData: any): TreeNode => {
    const result: TreeNode = {
        name: tree.name,
        amount: tree.amount,
        type: tree.type,
        paths: [],
    };

    for (const path of path_data) {
        let currentNode: TreeNode = tree;
        let resultNode: TreeNode = result;

        for (let i = 0; i < path.length; i += 2) {
            const pathIndex = path[i];
            const elementIndex = path[i + 1];

            if (pathIndex >= currentNode.paths.length || elementIndex >= currentNode.paths[pathIndex].length) {
                throw new Error(`Invalid path: ${path}`);
            }

            const nextNode = currentNode.paths[pathIndex][elementIndex];
            let newNode: TreeNode = {
                name: nextNode.name,
                amount: nextNode.amount,
                type: nextNode.type,
                paths: [],
            };

            if (!resultNode.paths[pathIndex]) {
                resultNode.paths[pathIndex] = [];
            }

            if (resultNode.paths[pathIndex][elementIndex]) {
                // If the node already exists, use it instead of creating a new one
                newNode = resultNode.paths[pathIndex][elementIndex];
            } else {
                // If the node doesn't exist, add it
                resultNode.paths[pathIndex][elementIndex] = newNode;
            }

            resultNode = newNode;
            currentNode = nextNode;
        }
    }

    cleanPathTree(result);
    return fixAmounts(result, amount, craftData);
};

export const getItem = (id: string, itemAssetData: any) => {
    for (const item of itemAssetData) {
        if (item.id === id.replace("minecraft:", "")) {
            return item;
        }
    }
    return null;
};

interface RecipeNode {
    name: string;
    amount: number;
    type: string | null;
    paths: RecipeNode[][];
}

export interface RecipeTree {
    [key: string]: RecipeNode;
}

export type FlowNodeData = {
    item: Item;
    amount: number;
    action: string | null;
    isStartOfBranch: boolean;
    isEndOfBranch: boolean;
    onRecipeView?: (recipe: any, action: string | null, item: Item, amount: number) => void;
    onItemReplace?: (pathData: number[][], nodeData: any) => void;
    fullRecipeTreePath?: number[];
    path: number[];
    treeParent: string;
    hasOtherMaterials: boolean;
    incomers: string[];
};

export interface FlowNode extends Node {
    data: FlowNodeData;
}

const elk = new ELK();

const generateId = (): string => Math.random().toString(36).substr(2, 9);

const hasOtherMaterials = (path: number[], tree: TreeNode): boolean => {
    if (!tree) return false;
    path.pop(); // get rid of the current item
    path.pop(); // get rid of current path (so we see adjacent paths)

    let currentNode: TreeNode | TreeNode[] = tree;
    for (const index of path) {
        if (Array.isArray(currentNode)) {
            currentNode = currentNode[index];
        } else {
            currentNode = currentNode.paths[index];
        }
    }
    const paths = (currentNode as TreeNode)?.paths;
    if (!paths) return false;
    return paths.length > 1;
};

const generateTree = (
    recipes: RecipeTree,
    itemAssetData: any,
    pathData: number[][],
    itemTrees: { [k: string]: TreeNode }
): { nodes: FlowNode[]; edges: Edge[] } => {
    if (!recipes) {
        return { nodes: [], edges: [] };
    }
    const nodesMap: { [key: string]: FlowNode } = {};
    const edges: Edge[] = [];
    const idMap: { [key: string]: string } = {};
    let edgeCounter = 0;

    const processNode = (recipe: RecipeNode, treeParent: string, hasReplacements: boolean, parentId?: string, currPath: number[] = []): string => {
        let nodeId = "";
        // if (idMap[recipe.name])
        //     nodeId = idMap[recipe.name];
        // else
        nodeId = generateId();
        idMap[recipe.name] = nodeId;

        const isStart = !parentId;
        const isEnd = recipe.paths.length === 0;

        // if (nodesMap[nodeId]) {
        //     nodesMap[nodeId].data.amount += recipe.amount;
        // } else {
        //     nodesMap[nodeId] = createNode(recipe, isStart, isEnd, itemAssetData, nodeId, type);
        // }

        if (parentId) {
            edges.push(createEdge(parentId, nodeId, edgeCounter++));
        }

        let createdNode = false;

        recipe.paths.forEach((path, pathIndex) => {
            if (!path || path.length === 0) return;

            // For type
            if (!createdNode) {
                const type = path[0].type ?? "obtain";
                nodesMap[nodeId] = createNode(recipe, isStart, isEnd, itemAssetData, nodeId, type, currPath, treeParent, hasReplacements);
                createdNode = true;
            }

            path.forEach((childRecipe, childIndex) => {
                // Find the item path with the full pathData cuz getPath removes other paths so indices (paths only) are not correct
                let realFullPathIndex = 0;
                const knownPath = [...currPath];
                for (let i = 0; i < pathData.length; i++) {
                    const testingPath = [...knownPath, pathData[i][knownPath.length], childIndex];
                    if (doesPathMatchPrefix(pathData[i], testingPath)) {
                        realFullPathIndex = i;
                        break;
                    }
                }
                const childId = processNode(
                    childRecipe,
                    treeParent,
                    hasOtherMaterials(
                        [...currPath, pathData[realFullPathIndex][currPath.length], childIndex],
                        itemTrees[treeParent.replace("minecraft:", "")]
                    ),
                    nodeId,
                    [...currPath, pathData[realFullPathIndex][currPath.length], childIndex]
                );
                edges.push(createEdge(nodeId, childId, edgeCounter++, pathIndex, childIndex));
            });
        });

        if (!createdNode) {
            const type = recipe.type || "obtain";
            nodesMap[nodeId] = createNode(recipe, isStart, isEnd, itemAssetData, nodeId, type, currPath, treeParent, hasReplacements);
        }

        return nodeId;
    };

    Object.values(recipes).forEach((recipe) => {
        let validPathInd = -1;
        let index = 0;
        for (const path of recipe.paths) {
            if (path && path.length > 0) {
                validPathInd = index;
                break;
            }
            index++;
        }
        if (validPathInd === -1) return;
        processNode(recipe, recipe.name, false);
    });

    return { nodes: Object.values(nodesMap), edges };
};

const createNode = (
    recipe: RecipeNode,
    isStart: boolean,
    isEnd: boolean,
    itemAssetData: any,
    nodeId: string,
    type: string,
    currPath: number[],
    treeParent: string,
    hasOtherMaterials: boolean
): FlowNode => ({
    id: nodeId,
    type: "recipeItem",
    data: {
        item: getItem(recipe.name, itemAssetData),
        amount: recipe.amount,
        action: type,
        isStartOfBranch: isStart,
        isEndOfBranch: isEnd,
        path: currPath,
        treeParent: treeParent.replace("minecraft:", ""),
        hasOtherMaterials: hasOtherMaterials,
        incomers: [],
    },
    position: { x: 0, y: 0 },
});

const createEdge = (source: string, target: string, counter: number, pathIndex?: number, childIndex?: number): Edge => ({
    id: `edge-${source}-${target}-${counter}${pathIndex !== undefined ? `-p${pathIndex}` : ""}${childIndex !== undefined ? `-c${childIndex}` : ""}`,
    source,
    type: "recipeEdge",
    target,
});

const calculateLayout = async (nodes: FlowNode[], edges: Edge[]): Promise<{ nodes: FlowNode[]; edges: Edge[] }> => {
    const graph = {
        id: "root",
        children: nodes.map((node) => ({
            id: node.id,
            width: 250, // Node width
            height: 75, // Node height
        })),
        edges: edges.map((edge) => ({
            id: edge.id,
            sources: [edge.source],
            targets: [edge.target],
        })),
    };

    // ELK Layout Options for Better Layer Separation
    const layoutOptions = {
        "elk.algorithm": "mrtree",
        "elk.direction": "RIGHT",
        "elk.spacing.nodeNode": "80",
    };

    try {
        const layout = await elk.layout(graph, { layoutOptions } as any);

        const xValues = layout.children?.map((child) => child.x || 0) || [];
        const minX = Math.min(...xValues);
        const maxX = Math.max(...xValues);
        const totalWidth = maxX - minX;

        // Create a map of original x positions to flipped x positions
        const xPositionMap = new Map<string, number>();

        const nodesWithPositions = nodes.map((node) => {
            const layoutNode = layout.children?.find((n: ElkNode) => n.id === node.id);
            if (!layoutNode) {
                console.warn(`No layout information found for node: ${node.id}`);
                return node;
            }

            // Flip the x-coordinate
            const flippedX = totalWidth - (layoutNode.x || 0);
            xPositionMap.set(node.id, flippedX);

            return {
                ...node,
                position: {
                    x: flippedX,
                    y: layoutNode.y || 0,
                },
            };
        });

        // Sort nodes by their flipped x position
        nodesWithPositions.sort((a, b) => (xPositionMap.get(b.id) || 0) - (xPositionMap.get(a.id) || 0));

        // Create a map of node IDs to their index in the sorted array
        const nodeIndexMap = new Map(nodesWithPositions.map((node, index) => [node.id, index]));

        const adjustedEdges = edges.map((edge) => {
            const sourceIndex = nodeIndexMap.get(edge.source) || 0;
            const targetIndex = nodeIndexMap.get(edge.target) || 0;

            // Swap source and target if needed to ensure left-to-right flow
            const [newSource, newTarget] = sourceIndex <= targetIndex ? [edge.source, edge.target] : [edge.target, edge.source];

            return {
                ...edge,
                source: newSource,
                target: newTarget,
                type: "recipeEdge",
            };
        });

        return { nodes: nodesWithPositions, edges: adjustedEdges };
    } catch (error) {
        console.error("Error during ELK layout calculation:", error);
        throw error;
    }
};

// Main function to generate the recipe tree flow diagram
export const generateRecipeTree = async (
    recipes: RecipeTree,
    itemAssetData: any,
    pathData: number[][],
    itemTrees: { [k: string]: TreeNode }
): Promise<{ nodes: FlowNode[]; edges: Edge[] }> => {
    const { nodes, edges } = generateTree(recipes, itemAssetData, pathData, itemTrees);
    // Find the final node (the one with isStartOfBranch: true)
    // const finalNode = nodes.find(node => node.data.isStartOfBranch) as FlowNode;
    // const startNodes = nodes.filter(node => node.data.isEndOfBranch);

    try {
        // Calculate and apply the layout
        const result = await calculateLayout(nodes, edges);
        // Include the finalNode in the returned object
        return result;
    } catch (error) {
        throw error;
    }
};

export const getShortestPath = (tree: TreeNode): number[][] => {
    let shortestPath: number[][] = [];
    let shortestPathLength = Infinity;
    for (const baseData of tree.base_item_paths!) {
        if (shortestPathLength > baseData.path_data.flat().length) {
            shortestPath = baseData.path_data;
            shortestPathLength = baseData.path_data.flat().length;
        }
    }
    return shortestPath;
};

const getBlocksInPath = (pathTree: TreeNode): string[] => {
    const stringData = JSON.stringify(pathTree.paths);
    const matches = stringData.match(/(minecraft:(\w|_)*)/g);
    return matches ?? [];
};

export const getPossiblePaths = (fullTree: TreeNode): number[][][] => {
    const paths: number[][][] = [];
    for (const baseData of fullTree.base_item_paths!) {
        paths.push(baseData.path_data);
    }
    return paths;
};

const getLongestPathLength = (fullTree: TreeNode, craftData: any): number => {
    let longestPathLength = 0;
    for (const path of getPossiblePaths(fullTree)) {
        const blocks = getBlocksInPath(getPath(fullTree, path, 1, craftData));
        if (blocks.length > longestPathLength) {
            longestPathLength = blocks.length;
        }
    }
    return longestPathLength;
};

export const getMatchingPath = (pathTree: TreeNode, fullTree: TreeNode, craftData: any): number[][] => {
    const blocksInPath = getBlocksInPath(pathTree);
    const longestPathLength = getLongestPathLength(JSON.parse(JSON.stringify(fullTree)), craftData);
    let bestScore = 0;
    let bestPath: number[][] = [];

    const possiblePaths = getPossiblePaths(fullTree);
    for (const path of possiblePaths) {
        const pathBlocks = Object.keys(fullTree.base_item_paths![getPathIndex(path, fullTree)].item_data);
        let score = 0;
        for (const block of blocksInPath) {
            if (pathBlocks.includes(block)) {
                score++;
            }
        }
        score = score / blocksInPath.length;
        score += ((longestPathLength - pathBlocks.length) / longestPathLength) * 0.05;
        if (score >= bestScore) {
            bestScore = score;
            bestPath = path;
        }
    }

    return bestPath;
};

export const getBaseItemsFromPath = (fullTree: TreeNode, path: number[][], amount: number): { [key: string]: number } => {
    let baseItems: { [key: string]: number } = {};
    for (const baseData of fullTree.base_item_paths!) {
        if (JSON.stringify(baseData.path_data) === JSON.stringify(path)) {
            baseItems = JSON.parse(JSON.stringify(baseData.item_data));
        }
    }

    if (amount === -1) {
        return baseItems;
    }

    const multiplier = getAmountMultiplier(fullTree, baseItems, amount);
    for (const baseItem in baseItems) {
        baseItems[baseItem] *= multiplier;
    }

    return baseItems;
};
