import { node } from "slate";
import ItemIcon, { Item } from "./ItemIcon";
import { Handle, Position, NodeProps, useReactFlow, Node, getIncomers, getOutgoers } from "@xyflow/react";
import ActionIcon from "./ActionIcon";
import { useContext, useEffect, useRef, useState } from "react";
import Tooltip from "./Tooltip";
import { FlowNodeData, getItemOptions, RecipeTree, TreeNode } from "../utils";
import ItemPickerTooltip from "./ItemPickerTooltip";
import { GlobalDataContext } from "../pages/PageTwo";

// TODO: We should have a popup per NODE so its relative to it.

const ArrowUp = () => (
    <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4.71308 1.3332C5.42168 0.624609 6.57826 0.624609 7.28686 1.3332L11.6335 5.67987C11.8288 5.87513 11.8288 6.19172 11.6335 6.38698C11.4383 6.58224 11.1217 6.58224 10.9264 6.38698L6.57975 2.04031C6.26168 1.72224 5.73826 1.72224 5.42019 2.04031L1.07352 6.38698C0.878262 6.58224 0.561679 6.58224 0.366417 6.38698C0.171155 6.19172 0.171155 5.87513 0.366417 5.67987L4.71308 1.3332Z"
            fill="#A39BBA"
        />
    </svg>
);

const ArrowDown = () => (
    <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0.366417 0.612999C0.561679 0.417737 0.878262 0.417737 1.07352 0.612999L5.42019 4.95967C5.73826 5.27774 6.26168 5.27774 6.57975 4.95967L10.9264 0.612999C11.1217 0.417737 11.4383 0.417737 11.6335 0.612999C11.8288 0.808261 11.8288 1.12484 11.6335 1.32011L7.28686 5.66677C6.57826 6.37537 5.42168 6.37537 4.71308 5.66677L0.366417 1.32011C0.171155 1.12484 0.171155 0.808261 0.366417 0.612999Z"
            fill="#A39BBA"
        />
    </svg>
);

const ACTION_TO_NAME: { [key: string]: string } = {
    craft: "Craft",
    smelt: "Smelt",
    stonecut: "Stonecut",
    get: "Get",
};

const getNodeText = (nodeData: { item: Item; amount: number; action: string | null; isStartOfBranch: boolean; isEndOfBranch: boolean }) => {
    let action = ACTION_TO_NAME[nodeData.action || "get"];
    if (nodeData.isEndOfBranch) {
        action = "Get";
    }

    return `${action} ${Math.ceil(nodeData.amount)} ${nodeData.item.name}`;
};

const getItemReplacements = (tree: TreeNode | undefined, path: number[], currPathData: any, nodeData: any) => {
    if (!tree) return [];
    const options = getItemOptions(tree, path, currPathData, {
        id: nodeData.item.id,
        amount: nodeData.amount,
        action: nodeData.action,
    });
    return options;
};

const RecipeItemNode = ({ data, id }: NodeProps) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const reactFlow = useReactFlow();
    const nodeRef = useRef(null);
    const nodeData = data as FlowNodeData;
    const itemPickerRef = useRef(null);
    const tooltipRef = useRef(null);
    const [showItemPicker, setItemPickerVisible] = useState(false);
    // const itemOptions = getItemReplacements(nodeData.recipeTree, nodeData.path, nodeData.paths, nodeData);
    const [itemOptions, setCurrItemOptions] = useState([]);
    const {
        itemAssetData,
        fullTrees,
        itemRecipes,
        itemPathDatas,
    }: {
        itemAssetData: any;
        fullTrees: { [key: string]: TreeNode };
        itemRecipes: { [key: string]: any };
        itemPathDatas: { [key: string]: any };
    } = useContext(GlobalDataContext);

    useEffect(() => {
        setCurrItemOptions(getItemReplacements(fullTrees[nodeData.treeParent], nodeData.path, itemPathDatas[nodeData.treeParent], nodeData));

        const handleMouseDown = (event: MouseEvent) => {
            if (!event.target) return;
            if (itemPickerRef.current && !(itemPickerRef.current as HTMLElement).contains(event.target as HTMLElement)) {
                setItemPickerVisible(false);
            }

            if ((event.target as HTMLElement).classList.contains("tooltip_switch")) return;
            if (tooltipRef.current && !(tooltipRef.current as HTMLElement).contains(event.target as HTMLElement)) {
                setTooltipOpen(false);
            }
        };

        document.addEventListener("mousedown", handleMouseDown, { capture: true });
        return () => {
            document.removeEventListener("mousedown", handleMouseDown, { capture: true });
        };
    }, []);

    useEffect(() => {
        const currNode = reactFlow.getNode(id);
        if (!currNode) return;
        getOutgoers({ id: id }, reactFlow.getNodes(), reactFlow.getEdges()).forEach((node) => {
            const item_id = (node.data.item as any).id;
            if (!currNode?.data.incomers) {
                currNode.data.incomers = [];
            }
            (currNode.data.incomers as string[]).push(item_id);
        });
    }, []);

    if (!nodeData.item) return null;
    return (
        <div
            ref={nodeRef}
            className={
                "rounded-lg flex flex-row items-center relative w-[150px] h-[60px] cursor-pointer border-[1px] border-mediumpurple " +
                (nodeData.isEndOfBranch ? "bg-lightpurple " : "bg-darkpurple ") +
                (nodeData.hasOtherMaterials || !nodeData.isEndOfBranch ? "justify-between " : "justify-center ") +
                (nodeData.isStartOfBranch ? "!bg-blue-600 " : "") +
                (tooltipOpen ? "!rounded-b-none" : "")
            }
            style={{ fontFamily: "minecraftFont" }}
        >
            {showItemPicker && (
                <div ref={itemPickerRef}>
                    <ItemPickerTooltip
                        itemAssetData={itemAssetData}
                        onChoose={(option: any) => {
                            // setItemPickerVisible(false);
                            if (nodeData.onItemReplace) nodeData.onItemReplace(option.pathData, nodeData);
                        }}
                        options={itemOptions}
                        position={{ x: 0, y: 0 }}
                    />
                </div>
            )}
            <div className="pl-2 w-full flex justify-center">
                <Tooltip content={getNodeText(nodeData)}>
                    <div className="flex flex-row justify-center items-center">
                        {!nodeData.isStartOfBranch && (
                            <Handle type="target" position={Position.Right} id={"b" + id} className="cursor-auto !border-mediumpurple" />
                        )}
                        {Math.ceil(nodeData.amount)} <p className="mx-1">&times;</p>
                        <ItemIcon item={nodeData.item} size={32} />
                        {!nodeData.isEndOfBranch && (
                            <Handle type="source" position={Position.Left} id={"a" + id} className="cursor-auto !border-mediumpurple" />
                        )}
                    </div>
                </Tooltip>
            </div>

            {/* {nodeData.hasOtherMaterials && <p className="text-orange-500 text-xl absolute top-0 right-1 font-serif">*</p>} */}
            {!nodeData.isEndOfBranch && (
                <div className="absolute left-[-70px]">
                    <ActionIcon action={nodeData.action} />
                </div>
            )}

            {(nodeData.hasOtherMaterials || !nodeData.isEndOfBranch) && (
                <div
                    className={
                        "tooltip_switch !min-w-[40px] h-full rounded-md rounded-l-none bg-purple flex justify-center items-center " +
                        (nodeData.isStartOfBranch ? "!bg-blue-900" : "")
                    }
                    onClick={(e) => {
                        e.stopPropagation();
                        setTooltipOpen(!tooltipOpen);
                    }}
                >
                    {tooltipOpen ? <ArrowUp /> : <ArrowDown />}
                </div>
            )}

            {tooltipOpen && (
                <div
                    ref={tooltipRef}
                    className="p-1 w-[150px] flex flex-col -left-[1px] bg-lightpurple border-[1px] border-mediumpurple absolute rounded-b-lg bottom-0 translate-y-full items-center"
                >
                    {!nodeData.isEndOfBranch && (
                        <button
                            className="bg-transparent text-xs"
                            onClick={() => {
                                const ingredients = new Set(nodeData.incomers);
                                const r = itemRecipes["minecraft:" + nodeData.item.id];
                                for (let recipe of r) {
                                    recipe = recipe["pattern"];
                                    const recipeIngredients = new Set(recipe.filter((ingredient: any) => ingredient !== " "));
                                    if (Array.from(ingredients).every((ingredient) => recipeIngredients.has("minecraft:" + ingredient))) {
                                        if (nodeData.onRecipeView) nodeData.onRecipeView(recipe, nodeData.action, nodeData.item, nodeData.amount);
                                        return;
                                    }
                                }
                            }}
                        >
                            View Recipe
                        </button>
                    )}

                    {nodeData.hasOtherMaterials && !nodeData.isStartOfBranch && !nodeData.isEndOfBranch && (
                        <div className="w-[90%] bg-mediumpurple h-[1px] opacity-50"></div>
                    )}

                    {nodeData.hasOtherMaterials && !nodeData.isStartOfBranch && (
                        <button
                            className="bg-transparent text-xs"
                            onClick={() => {
                                console.log(itemOptions);
                                setItemPickerVisible(true);
                            }}
                        >
                            Replace Item
                        </button>
                    )}
                </div>
            )}
        </div>
    );
};

export default RecipeItemNode;
