import React, { useState, useEffect, useRef } from "react";
import blockIds from "./data/block_ids";

import Pink from "./assets/pink.png";
import Brown from "./assets/brown.png";
import Green from "./assets/green.png";
import Black from "./assets/black.png";

import Center from "./assets/center.png";
import SideMid from "./assets/side_mid.png";
import Connect from "./assets/connect.png";
import ContrastPill from "./components/ContrastPill";
import * as NBT from "nbtify";
import ValueChangePill from "./components/ValueChangePill";
import Page from "../../components/Page";

const generateCircleCoordinates = (radius: number) => {
    const coordinates = [];

    for (let x = -radius; x <= radius; x++) {
        for (let z = -radius; z <= radius; z++) {
            if (Math.round(Math.sqrt(x * x + z * z)) === radius) {
                coordinates.push([x, z]);
            }
        }
    }

    return coordinates;
};

const center = new Image();
const side_mid = new Image();
const connect = new Image();

center.src = Center;
side_mid.src = SideMid;
connect.src = Connect;

const getPrioritizedAdjacentBlock = (blocks: any, coordinate: any) => {
    const x = coordinate[0];
    const y = coordinate[1];

    if (blocks[`${x - 1},${y}`]) return blocks[`${x - 1},${y}`];
    if (blocks[`${x + 1},${y}`]) return blocks[`${x + 1},${y}`];
    if (blocks[`${x},${y - 1}`]) return blocks[`${x},${y - 1}`];
    if (blocks[`${x},${y + 1}`]) return blocks[`${x},${y + 1}`];

    if (blocks[`${x - 1},${y - 1}`]) return blocks[`${x - 1},${y - 1}`];
    if (blocks[`${x + 1},${y - 1}`]) return blocks[`${x + 1},${y - 1}`];
    if (blocks[`${x - 1},${y + 1}`]) return blocks[`${x - 1},${y + 1}`];
    if (blocks[`${x + 1},${y + 1}`]) return blocks[`${x + 1},${y + 1}`];

    return null;
};

function App() {
    const [radius, setRadius] = useState(1);
    const [zoom, setZoom] = useState(1);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const canvasSize = window.innerWidth > 1000 ? 377 : window.innerWidth * 0.58; // Fixed canvas size
    const blockSize = 16; // Assuming our images are 32x32 pixels
    const [panX, setPanX] = useState(0);
    const [panY, setPanY] = useState(0);
    const [isDragging, setIsDragging] = useState(false);
    const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
    const [selectedContrast, setSelectedContrast] = useState(1);
    const [rotationStatus, setRotationStatus] = useState("STATIC");
    const [dimensionStatus, setDimensionStatus] = useState("2D");
    const [blockMap, setBlockMap] = useState({});

    const [iter1Image, setIter1Image] = useState(new Image());
    const [iter2Image, setIter2Image] = useState(new Image());

    const downloadSchem = async () => {
        const size = 2 * radius + 1;
        const posOffset = Math.floor(size / 2);

        // read ./data/emptyNBT.nbt
        const NBTData = {
            "Made With": "WiseHosting Circle Generator",
            Width: size,
            Height: 1,
            Length: size,
            Materials: "Alpha",
            Blocks: new Int8Array(size * size),
            Data: new Int8Array(size * size),
            x: 0,
            y: 0,
            z: 0,
        };
        const amounts: { [key: string]: number } = {};
        for (let [coord, block] of Object.entries(blockMap) as [string, string][]) {
            if (block === "iter1") {
                if (selectedContrast === 1) block = "brown_wool";
                if (selectedContrast === 2) block = "pink_wool";
                if (selectedContrast === 3) block = "green_wool";
            } else if (block === "iter2") {
                block = "black_wool";
            }

            let [x, y] = coord.split(",").map(Number);
            x += posOffset;
            y += posOffset;
            // console.log(x, y, block);
            if (!amounts[block]) amounts[block] = 0;
            amounts[block]++;
            if (block) {
                NBTData.Blocks.set([blockIds[block].id], y * size + x);
                NBTData.Data.set([blockIds[block].data], y * size + x);
            }
        }

        // Write to NBT
        const nbt = new NBT.NBTWriter();
        const buffer = nbt.write(NBTData);
        const blob = new Blob([buffer], { type: "application/octet-stream" });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "circle.schematic";
        a.click();
    };

    useEffect(() => {
        const iter1 = new Image();
        const iter2 = new Image();

        if (selectedContrast === 1) iter1.src = Brown;
        if (selectedContrast === 2) iter1.src = Pink;
        if (selectedContrast === 3) iter1.src = Green;
        iter2.src = Black;

        setIter1Image(iter1);
        setIter2Image(iter2);
    }, [selectedContrast]);

    const handleMouseDown = (event: React.MouseEvent<HTMLCanvasElement>) => {
        if (zoom > 1) {
            console.log("start drag");
            setIsDragging(true);
            setDragStart({ x: event.clientX - panX, y: event.clientY - panY });
        }
    };

    const handleMouseMove = (event: React.MouseEvent<HTMLCanvasElement>) => {
        if (isDragging) {
            const newPanX = event.clientX - dragStart.x;
            const newPanY = event.clientY - dragStart.y;
            console.log(newPanX, newPanY);
            setPanX(newPanX);
            setPanY(newPanY);
        }
    };

    const handleMouseUp = () => {
        setIsDragging(false);
        console.log("end drag");
    };

    const handleTouchDown = (event: React.TouchEvent<HTMLCanvasElement>) => {
        if (zoom > 1) {
            setIsDragging(true);
            setDragStart({ x: event.touches[0].clientX - panX, y: event.touches[0].clientY - panY });
        }
    };

    const handleTouchMove = (event: React.TouchEvent<HTMLCanvasElement>) => {
        if (isDragging) {
            const newPanX = event.touches[0].clientX - dragStart.x;
            const newPanY = event.touches[0].clientY - dragStart.y;
            setPanX(newPanX);
            setPanY(newPanY);
        }
    };

    const handleTouchUp = () => {
        setIsDragging(false);
    };

    const handleMouseLeave = () => {
        setIsDragging(false);
    };

    useEffect(() => {
        // if (zoom <= 1) {
        //     resetPan();
        // }

        const canvas = canvasRef.current;
        if (!canvas) return;

        const ctx = canvas.getContext("2d");
        if (!ctx) return;

        const coordinates = generateCircleCoordinates(radius);
        const scale = Math.min(4, (canvasSize - blockSize) / (radius * 2 * blockSize)) * zoom * 0.9;

        canvas.width = canvasSize;
        canvas.height = canvasSize;

        // Clear canvas with transparent background
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.fillStyle = "#342D4E";
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        const totalSize = (2 * radius + 1) * blockSize * scale;

        // Calculate the maximum allowed pan
        const maxPanX = Math.max(0, (totalSize - canvasSize) / 2);
        const maxPanY = Math.max(0, (totalSize - canvasSize) / 2);

        // Clamp the pan values
        const clampedPanX = -Math.max(-maxPanX, Math.min(maxPanX, panX));
        const clampedPanY = -Math.max(-maxPanY, Math.min(maxPanY, panY));

        console.log(panX.toFixed(1), panY.toFixed(1));

        // Calculate the offset
        let offsetX = (canvasSize - totalSize) / 2 - clampedPanX;
        let offsetY = (canvasSize - totalSize) / 2 - clampedPanY;

        // Limit panning to keep the circle within view
        const maxX = Math.max(...coordinates.map((c) => c[0]));
        const maxY = Math.max(...coordinates.map((c) => c[1]));

        // setBlockMap({});
        const bMap: any = {};
        for (let i = 0; i < coordinates.length; i++) {
            const adjacentBlock = getPrioritizedAdjacentBlock(bMap, coordinates[i]);
            const blockImage = adjacentBlock === "iter1" ? "iter2" : "iter1";
            bMap[`${coordinates[i][0]},${coordinates[i][1]}`] = blockImage;

            // Draw coordinate as iter1 or iter2
            ctx.drawImage(
                blockImage === "iter1" ? iter1Image : iter2Image,
                offsetX + (coordinates[i][0] + radius) * blockSize * scale,
                offsetY + (coordinates[i][1] + radius) * blockSize * scale,
                blockSize * scale,
                blockSize * scale
            );
        }
        setBlockMap(bMap);

        // Draw center
        ctx.drawImage(center, offsetX + radius * blockSize * scale, offsetY + radius * blockSize * scale, blockSize * scale, blockSize * scale);
        setBlockMap((prev) => ({ ...prev, ["0,0"]: "diamond_block" }));

        // Draw side centers
        ctx.drawImage(
            side_mid,
            offsetX + (radius + maxX) * blockSize * scale,
            offsetY + radius * blockSize * scale,
            blockSize * scale,
            blockSize * scale
        );
        ctx.drawImage(
            side_mid,
            offsetX + radius * blockSize * scale,
            offsetY + (radius + maxY) * blockSize * scale,
            blockSize * scale,
            blockSize * scale
        );
        ctx.drawImage(
            side_mid,
            offsetX + (radius - maxX) * blockSize * scale,
            offsetY + radius * blockSize * scale,
            blockSize * scale,
            blockSize * scale
        );
        ctx.drawImage(
            side_mid,
            offsetX + radius * blockSize * scale,
            offsetY + (radius - maxY) * blockSize * scale,
            blockSize * scale,
            blockSize * scale
        );

        setBlockMap((prev) => ({ ...prev, [`${maxX},0`]: "gold_block" }));
        setBlockMap((prev) => ({ ...prev, [`${-maxX},0`]: "gold_block" }));
        setBlockMap((prev) => ({ ...prev, [`${0},${maxY}`]: "gold_block" }));
        setBlockMap((prev) => ({ ...prev, [`${0},${-maxY}`]: "gold_block" }));

        // Draw connects - X+
        for (let i = 1; i < maxX; i++) {
            ctx.drawImage(
                connect,
                offsetX + (radius + i) * blockSize * scale,
                offsetY + radius * blockSize * scale,
                blockSize * scale,
                blockSize * scale
            );
            setBlockMap((prev) => ({ ...prev, [`${-i},0`]: "iron_block" }));
        }

        // Draw connects - X-
        for (let i = 1; i < maxX; i++) {
            ctx.drawImage(
                connect,
                offsetX + (radius - i) * blockSize * scale,
                offsetY + radius * blockSize * scale,
                blockSize * scale,
                blockSize * scale
            );
            setBlockMap((prev) => ({ ...prev, [`${i},0`]: "iron_block" }));
        }

        // Draw connects - Y+
        for (let i = 1; i < maxY; i++) {
            ctx.drawImage(
                connect,
                offsetX + radius * blockSize * scale,
                offsetY + (radius + i) * blockSize * scale,
                blockSize * scale,
                blockSize * scale
            );
            setBlockMap((prev) => ({ ...prev, [`${0},${-i}`]: "iron_block" }));
        }

        // Draw connects - Y-
        for (let i = 1; i < maxY; i++) {
            ctx.drawImage(
                connect,
                offsetX + radius * blockSize * scale,
                offsetY + (radius - i) * blockSize * scale,
                blockSize * scale,
                blockSize * scale
            );
            setBlockMap((prev) => ({ ...prev, [`${0},${i}`]: "iron_block" }));
        }
    }, [radius, zoom, panX, panY, iter1Image, iter2Image]);

    return (
        <Page toolName="circle_gen">
            <div className="flex flex-row max-md:flex-col gap-3 justify-center pt-5">
                <canvas
                    ref={canvasRef}
                    width={canvasSize}
                    height={canvasSize}
                    onMouseDown={handleMouseDown}
                    onMouseMove={handleMouseMove}
                    onMouseUp={handleMouseUp}
                    onTouchStart={handleTouchDown}
                    onTouchEnd={handleTouchUp}
                    onTouchMove={handleTouchMove}
                    onMouseLeave={handleMouseLeave}
                    style={{ cursor: zoom > 1 ? "move" : "default", borderRadius: "10px" }}
                    className="border-[1px] border-mediumpurple"
                ></canvas>
                <div className="flex flex-col justify-between gap-2">
                    <div className="flex flex-col gap-1">
                        <p>RADIUS</p>
                        <ValueChangePill
                            onChange={(value) => {
                                setRadius(value);
                            }}
                            value={radius}
                        />
                    </div>

                    <div className="flex flex-col gap-1">
                        <p>ZOOM</p>
                        <ValueChangePill
                            minValue={1}
                            maxValue={6}
                            onChange={(value) => {
                                setZoom(value);
                            }}
                            value={zoom}
                        />
                    </div>

                    <div className="flex flex-col gap-4">
                        <span className="text-sm font-bold text-gray-400 uppercase">BLOCK CONTRAST</span>
                        <div className="flex flex-row gap-4">
                            <ContrastPill
                                color1="#a8614c"
                                color2="#342d4e"
                                onSelect={(a) => setSelectedContrast(1)}
                                isSelected={selectedContrast === 1}
                            />
                            <ContrastPill
                                color1="#e757f9"
                                color2="#342d4e"
                                onSelect={(a) => setSelectedContrast(2)}
                                isSelected={selectedContrast === 2}
                            />
                            <ContrastPill
                                color1="#459d24"
                                color2="#342d4e"
                                onSelect={(a) => setSelectedContrast(3)}
                                isSelected={selectedContrast === 3}
                            />
                        </div>
                    </div>

                    <div className="flex flex-col gap-4">
                        <span className="text-sm font-bold text-gray-400 uppercase">ROTATION & DIMENSION</span>
                        <div className="flex flex-row gap-2">
                            <button
                                disabled
                                title="Rotation is not available yet"
                                onClick={() => setRotationStatus("ROTATE")}
                                className={
                                    "font-bold w-full" +
                                    (rotationStatus !== "ROTATE"
                                        ? " bg-transparent text-[#6a6282] border-2 border-[#6a6282]"
                                        : " bg-[#00b8ff] text-black")
                                }
                            >
                                ROTATE
                            </button>
                            <button
                                onClick={() => setRotationStatus("STATIC")}
                                className={
                                    "font-bold w-full" +
                                    (rotationStatus !== "STATIC"
                                        ? " bg-transparent text-[#6a6282] border-2 border-[#6a6282] "
                                        : " bg-[#00b8ff] text-black")
                                }
                            >
                                STATIC
                            </button>
                        </div>
                        <div className="flex flex-row gap-2">
                            <button
                                onClick={() => setDimensionStatus("2D")}
                                className={
                                    "font-bold w-full" +
                                    (dimensionStatus !== "2D"
                                        ? " bg-transparent text-[#6a6282] border-2 border-[#6a6282]"
                                        : " bg-[#00b8ff] text-black")
                                }
                            >
                                2D
                            </button>
                            <button
                                disabled
                                title="3D is not available yet"
                                onClick={() => setDimensionStatus("3D")}
                                className={
                                    "font-bold w-full" +
                                    (dimensionStatus !== "3D"
                                        ? " bg-transparent text-[#6a6282] border-2 border-[#6a6282] "
                                        : " bg-[#00b8ff] text-black")
                                }
                            >
                                3D
                            </button>
                        </div>
                    </div>
                    <button onClick={() => downloadSchem()} className={"font-bold w-full bg-[#00b8ff] text-black mt-4"}>
                        Download
                    </button>
                </div>
            </div>
        </Page>
    );
}

export default App;
