// We export the tools and images here so that we can use them in other components.

import MotdEditor from "./pages/motd_editor/MotdEditorTool";
import CoordCalc from "./pages/coord_calc/App";
import SkinGrabber from "./pages/skin_grabber/App";
import TickCalc from "./pages/tick_calculator/App";
import CircleGen from "./pages/circle_generator/App";
import CraftingGen from "./pages/crafting_generator/App";
import NbtEditor from "./pages/nbt_editor/App";
import ResourceCalc from "./pages/resource_calc/App";
import MapGenerator from "./pages/map_generator/App";

export const tools = [
    {
        title: "MOTD Editor",
        href: "/motd_editor",
        image: require("./assets/images/tools/motdeditor.png"),
        description: "Lorem ipsum dolor sit amet consectetur. Et massa volutpat nisi risus elit. Turpis purus eu rhoncus viverra euismod adipiscing turpis mi blandit.",
        color: "#85B079B2",
        domain: "minecraftmotdgenerator.com",
        component: MotdEditor,
        key: "motd_editor",
        version: "v3.7"
    },
    {
        title: "Coordinate Calculator",
        href: "/coord_calc",
        image: require("./assets/images/tools/coordinatecalculator.png"),
        description: "This is short text about Minecraft tools. Also placeholder text can be longer...",
        color: "#EBAC15B2",
        component: CoordCalc,
        key: "coord_calc",
        version: "v3.7"
    },
    {
        title: "Tick Calculator",
        href: "/tick_calc",
        image: require("./assets/images/tools/tickcalculator.png"),
        description: "This is short text about Minecraft tools. Also placeholder text can be longer...",
        color: "#71C418B2",
        component: TickCalc,
        key: "tick_calc",
        version: "v3.7"
    },
    {
        title: "Circle Generator",
        href: "/circle_gen",
        image: require("./assets/images/tools/circlegenerator.png"),
        description: "This is short text about Minecraft tools. Also placeholder text can be longer...",
        color: "#4EC33FB2",
        component: CircleGen,
        key: "circle_gen",
        version: "v3.7"
    },
    {
        title: "Skin Grabber",
        href: "/skin_grabber",
        image: require("./assets/images/tools/skingrabber.png"),
        description: "This is short text about Minecraft tools. Also placeholder text can be longer...",
        color: "#EED4ABB2",
        component: SkinGrabber,
        key: "skin_grabber",
        version: "v3.7"
    },
    {
        title: "Crafting Generator",
        href: "/crafting_gen",
        image: require("./assets/images/tools/craftinggenerator.png"),
        description: "This is short text about Minecraft tools. Also placeholder text can be longer...",
        color: "#846B45B2",
        component: CraftingGen,
        key: "crafting_gen",
        version: "v3.7"
    },
    {
        title: "Resource Calculator",
        href: "/resource_calc",
        image: require("./assets/images/tools/resourcecalculator.png"),
        description: "This is short text about Minecraft tools. Also placeholder text can be longer...",
        color: "#9C7C98B2",
        component: ResourceCalc,
        key: "resource_calc",
        version: "v3.7"
    },
    {
        title: "NBT Editor",
        href: "/nbt_editor",
        image: require("./assets/images/tools/nbteditor.png"),
        description: "This is short text about Minecraft tools. Also placeholder text can be longer...",
        color: "#B4732FB2",
        component: NbtEditor,
        key: "nbt_editor",
        version: "v3.7"
    },
    {
        title: "Map Generator",
        href: "/map_generator",
        image: require("./assets/images/tools/mapgenerator.png"),
        description: "This is short text about Minecraft tools. Also placeholder text can be longer...",
        color: "#E74C3CB2",
        component: MapGenerator,
        key: "map_generator",
        version: "v3.7"
    },
];

// Define tool type
export type Tool = {
    title: string;
    href: string;
    image: string;
    description: string;
    color: string;
    domain?: string;
    component: any;
    key: string;
    version: string;
};