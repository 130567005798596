import ColorOption from "./ColorOption";

interface Params {
    setColor: (color: string) => void;
    selectedColor: string;
}

const Colors = ({ setColor, selectedColor }: Params) => {
    const colors = [
        "#000000",
        "#0000AA",
        "#00AA00",
        "#00AAAA",
        "#AA0000",
        "#AA00AA",
        "#FFAA00",
        "#AAAAAA",
        "#555555",
        "#5555FF",
        "#55FF55",
        "#55FFFF",
        "#FF5555",
        "#FF55FF",
        "#FFFF55",
        "#FFFFFF",
    ];

    const colorSelect = (color: string) => {
        setColor(color);
    };

    return (
        <div className="w-full flex flex-row gap-1 flex-wrap">
            {colors.map((color, index) => (
                <ColorOption key={index} color={color} selected={selectedColor === color} onSelect={colorSelect} />
            ))}
        </div>
    );
};

export default Colors;
