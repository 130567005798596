import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import tw from "twin.macro";

interface ModalOverlayProps {
    visible?: boolean;
}

const ModalOverlay = styled.div<ModalOverlayProps>`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(8px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 15;
`;

const ModalContent = styled.div<ModalOverlayProps>`
    background: #20193a;
    padding: 20px;
    border-radius: 10px;
    position: relative;
    margin: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 90vw; /* Default for mobile */
    max-width: 500px; /* Max width for larger screens */
    min-width: 300px; /* Ensure modal has a minimum width for smaller screens */

    /* Centering the content */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media (max-width: 768px) {
        width: 90vw; /* Adjust width for mobile */
        padding: 15px;
    }

    @media (min-width: 769px) {
        width: 500px; /* Fixed width on larger screens */
        padding: 20px;
    }
`;

interface Params {
    isOpen: boolean;
    onClose: () => void;
    title: string;
    children: React.ReactNode;
}

const Modal = ({ isOpen, onClose, title, children }: Params) => {
    const overlayRef = useRef(null);
    const contentRef = useRef(null);
    const mouseDownOnOverlayRef = useRef(false);

    useEffect(() => {
        const handleEscape = (event: KeyboardEvent) => {
            if (event.key === "Escape") {
                onClose();
            }
        };

        if (isOpen) {
            document.addEventListener("keydown", handleEscape);
        } else {
        }

        return () => {
            document.removeEventListener("keydown", handleEscape);
        };
    }, [isOpen]);

    useEffect(() => {
        const handleMouseDown = (e: MouseEvent) => {
            mouseDownOnOverlayRef.current = e.target === overlayRef.current;
        };

        const handleMouseUp = (e: MouseEvent) => {
            if (mouseDownOnOverlayRef.current && e.target === overlayRef.current) {
                onClose();
            }
            mouseDownOnOverlayRef.current = false;
        };

        document.addEventListener("mousedown", handleMouseDown);
        document.addEventListener("mouseup", handleMouseUp);

        return () => {
            document.removeEventListener("mousedown", handleMouseDown);
            document.removeEventListener("mouseup", handleMouseUp);
        };
    }, []);

    if (!isOpen) return null;

    return (
        <ModalOverlay ref={overlayRef} visible={isOpen}>
            <ModalContent ref={contentRef} visible={isOpen} onClick={(e) => e.stopPropagation()}>
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-xl font-bold">{title}</h2>
                    <button className="bg-transparent border-none text-xl cursor-pointer text-gray-500 hover:text-gray-700" onClick={onClose}>
                        &times;
                    </button>
                </div>
                <div className="text-base leading-relaxed">{children}</div>
            </ModalContent>
        </ModalOverlay>
    );
};

export default Modal;
