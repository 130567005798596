import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Homepage from "./Homepage";
import NavigationBar from "./components/NavigationBar";
import Footer from "./components/Footer";
import { tools } from "./tools";

function App() {
    // Read domains from tools.ts
    const domains = tools.map((tool) => tool.domain);
    var tool = null;

    // Check if the current domain is in the list of domains
    if (domains.includes(window.location.hostname)) {
        // If it is, redirect to the correct tool
        tool = tools.find((tool) => tool.domain === window.location.hostname);
    }


    return (
        <BrowserRouter>
            <NavigationBar />
            <Routes>
                {tool && <Route path="/" Component={tool.component} />}

                {/* Are we in development mode? */}
                {!process.env.LIVE ? (
                    <>
                        {tools.map((tool) => (
                            <Route path={tool.href} Component={tool.component} />
                        ))}
                    </>
                ) : (
                    tools.map((tool) => (
                        <Route path={tool.domain} Component={tool.component} />
                    ))
                )}

                <Route path="/" Component={Homepage} />
            </Routes>
            <Footer />
        </BrowserRouter>
    );
}

export default App;
