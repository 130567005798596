import React, { useEffect } from "react";

interface Props {
    onChange: (value: number) => void;
    value: number;
}

const PlusIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 5.25C12.4142 5.25 12.75 5.58579 12.75 6V11.25H18C18.4142 11.25 18.75 11.5858 18.75 12C18.75 12.4142 18.4142 12.75 18 12.75H12.75V18C12.75 18.4142 12.4142 18.75 12 18.75C11.5858 18.75 11.25 18.4142 11.25 18V12.75H6C5.58579 12.75 5.25 12.4142 5.25 12C5.25 11.5858 5.58579 11.25 6 11.25H11.25V6C11.25 5.58579 11.5858 5.25 12 5.25Z"
            fill="#D1CCE0"
        />
    </svg>
);

const MinusIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6 12H18" stroke="#A39BBA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

const ValueChangePill = ({ onChange, value }: Props) => {
    const [inputValue, setInputValue] = React.useState(value.toString());

    useEffect(() => {
        onChange(parseInt(inputValue));
    }, [inputValue, onChange]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        if (newValue === "") {
            setInputValue("");
            return;
        }

        if (!/^\d+$/.test(newValue)) {
            return;
        }

        const numValue = parseInt(newValue);
        if (numValue >= 0 && numValue <= 100) {
            setInputValue(newValue);
        }
    };

    const handleInputBlur = () => {
        if (inputValue === "") {
            setInputValue("0");
        }
    };

    const increment = () => {
        if (value < 100) {
            const newValue = value + 1;
            setInputValue(newValue.toString());
        }
    };

    const decrement = () => {
        if (value > 0) {
            const newValue = value - 1;
            setInputValue(newValue.toString());
        }
    };

    return (
        <div className="bg-lightpurple border-mediumpurple border-2 rounded-2xl flex flex-row w-full h-[50px]">
            <input
                type="text"
                className="w-full bg-lightpurple outline-none px-6 rounded-l-2xl"
                value={inputValue}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
            />
            <button
                className="bg-purple aspect-square h-full rounded-none border-r-mediumpurple border-r-[1px] p-0 flex justify-center items-center"
                onClick={decrement}
            >
                <MinusIcon />
            </button>
            <button className="bg-purple aspect-square h-full rounded-none rounded-r-2xl p-0 flex justify-center items-center" onClick={increment}>
                <PlusIcon />
            </button>
        </div>
    );
};

export default ValueChangePill;
