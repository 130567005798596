import React, { useState, ReactNode, useEffect, useRef } from "react";
import ReactDOM from "react-dom";

interface TooltipProps {
    children: ReactNode;
    content: string;
}

const Tooltip: React.FC<TooltipProps> = ({ children, content }) => {
    const [isVisible, setIsVisible] = useState(false);
    const [position, setPosition] = useState({ top: 0, left: 0 });
    const triggerRef = useRef<HTMLDivElement>(null);
    const tooltipRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const updatePosition = () => {
            if (triggerRef.current && tooltipRef.current) {
                const triggerRect = triggerRef.current.getBoundingClientRect();
                const tooltipRect = tooltipRef.current.getBoundingClientRect();

                const top = triggerRect.top - tooltipRect.height; // 10px gap
                const left = triggerRect.left + triggerRect.width / 2;

                setPosition({ top, left });
            }
        };

        if (isVisible) {
            updatePosition();
            window.addEventListener("scroll", updatePosition);
            window.addEventListener("resize", updatePosition);
        }

        return () => {
            window.removeEventListener("scroll", updatePosition);
            window.removeEventListener("resize", updatePosition);
        };
    }, [isVisible]);

    const tooltipContent = (
        <div
            ref={tooltipRef}
            className={`fixed z-50 px-3 py-2 text-sm font-medium text-white bg-black rounded-lg shadow-sm tooltip transition-opacity duration-300 ${
                isVisible ? "opacity-100" : "opacity-0 pointer-events-none"
            }`}
            style={{
                top: `${position.top}px`,
                left: `${position.left}px`,
                transform: "translateX(-50%)",
            }}
        >
            {content}
            <div
                className="tooltip-arrow"
                style={{
                    position: "absolute",
                    left: "50%",
                    transform: "translateX(-50%)",
                    bottom: "-4px",
                    borderWidth: "4px 4px 0 4px",
                    borderColor: "rgb(17, 24, 39) transparent transparent transparent",
                }}
            />
        </div>
    );

    return (
        <>
            <div ref={triggerRef} onMouseEnter={() => setIsVisible(true)} onMouseLeave={() => setIsVisible(false)}>
                {children}
            </div>
            {isVisible && ReactDOM.createPortal(tooltipContent, document.body)}
        </>
    );
};

export default Tooltip;
