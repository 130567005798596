import React, { useEffect, useState } from "react";
import InputField from "./components/InputField";
import CraftingTable from "./components/CraftingTable";
import MaterialSelect from "./components/MaterialSelect";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import OptionPicker from "./components/OptionPicker";

interface Material {
    item: Item;
    amount: number;
}

interface Item {
    name: string;
    id: string;
    offsetX: number;
    offsetY: number;
}

interface CraftingRecipe {
    type?: string;
    group?: string;
    pattern?: string[];
    key?: Record<string, { item: string; data?: string }>;
    result?: { item: string; count: number };
}

const CopyIcon = ({ className }: { className: string }) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 24 24" strokeWidth={2} stroke="#1e1e1e" className={"size-7" + className}>
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75"
        />
    </svg>
);

const App = () => {
    const [itemSelectVisible, setItemSelectVisible] = useState<boolean>(false);
    const [itemData, setItemData] = useState<Item[]>([]);
    const [clickedSlot, setClickedSlot] = useState<number | null>(null);
    const [slotData, setSlotData] = useState<Material[]>([]);
    const [recipeName, setRecipeName] = useState<string>("my_recipe");
    const [recipeType, setRecipeType] = useState<string>("SHAPED");
    const [recipeGroup, setRecipeGroup] = useState<string>("");
    const [strictRecipe, setStrictRecipe] = useState<string>("YES");
    const [command, setCommand] = useState<CraftingRecipe>({});
    const tableElem = React.createRef<HTMLDivElement>();

    const handleMaterialSelected = (item: Item, amount: number) => {
        const slotIndex = clickedSlot;
        setClickedSlot(null);
        if (slotIndex === null) return;

        const newSlotData = [...slotData];
        newSlotData[slotIndex] = { item, amount };
        setSlotData(newSlotData);
    };

    const handleSlotClicked = (slotIndex: number) => {
        setClickedSlot(slotIndex);
        setItemSelectVisible(true);
    };

    useEffect(() => {
        fetch("https://raw.githubusercontent.com/K9Developer/MinecraftData/refs/heads/main/items2/atlas_metadata2.json")
            .then((res) => res.json())
            .then((data: Item[]) => {
                setItemData(data);
            });
    }, []);

    const generateCommand = () => {
        const crafting: CraftingRecipe = {};
        crafting.type = recipeType === "SHAPED" ? "minecraft:crafting_shaped" : "minecraft:crafting_shapeless";
        crafting.group = recipeGroup;
        const letters = "ABCDEFGHI";
        for (let i = 0; i < 9; i++) {
            if (slotData[i]) {
                crafting.key = { ...crafting.key, [letters[i]]: { item: "minecraft:" + slotData[i].item.id } };
            }
        }

        const pattern = ["", "", ""];
        for (let i = 0; i < 9; i++) {
            console.log(i, slotData[i] ? letters[i] : strictRecipe === "YES" ? " " : "");
            pattern[Math.floor(i / 3)] += slotData[i] ? letters[i] : strictRecipe === "YES" ? " " : "";
        }
        console.log(pattern);
        crafting.pattern = pattern;
        crafting.result = { item: "minecraft:" + (slotData[9]?.item.id ?? "air"), count: slotData[9]?.amount ?? 1 };
        return crafting;
    };

    useEffect(() => {
        setCommand(generateCommand());
    }, [slotData, recipeType, recipeGroup, strictRecipe]);

    const downloadDatapack = async () => {
        const zip = new JSZip();

        const packMcmeta = {
            pack: {
                pack_format: 48,
                description: "Generated with WiseHosting crafting generator",
            },
        };
        zip.file("pack.mcmeta", JSON.stringify(packMcmeta));

        // Create data/crafting/recipe/abc.json
        const recipeFolder = zip.folder("data")?.folder("crafting")?.folder("recipes");
        const crafting_recipe = command;
        recipeFolder?.file(`${recipeName}.json`, JSON.stringify(crafting_recipe));

        // Generate the zip file
        const content = await zip.generateAsync({ type: "blob" });
        saveAs(content, "datapack.zip");
    };

    return (
        <div className="flex justify-center items-center w-full">
            <div className="flex flex-col h-fit items-center gap-10 justify-center w-[100%] max-w-[600px] relative p-3" ref={tableElem}>
                <MaterialSelect
                    setClickedSlot={setClickedSlot}
                    onSelected={handleMaterialSelected}
                    items={itemData}
                    visible={itemSelectVisible}
                    setVisible={setItemSelectVisible}
                    showCount={clickedSlot === 9}
                />
                <div className="flex flex-col gap-5">
                    <div className="flex flex-row">
                        <InputField
                            label="NAME"
                            description="The name of the crafting recipe that will be used in commands"
                            onChange={setRecipeName}
                            value={recipeName}
                        />
                        <InputField
                            label="GROUP"
                            description="The crafting group this recipe belongs to"
                            value={recipeGroup}
                            onChange={setRecipeGroup}
                        />
                    </div>
                    <div className="flex flex-row gap-5">
                        <OptionPicker
                            options={[
                                { label: "Shaped", value: "SHAPED" },
                                { label: "Shapeless", value: "SHAPELESS" },
                            ]}
                            title="Type"
                            description="Whether the placement of items in the crafting table will matter"
                            onChange={(a: string) => setRecipeType(a)}
                        />
                        <OptionPicker
                            options={[
                                { label: "Yes", value: "YES" },
                                { label: "No", value: "NO" },
                            ]}
                            title="Strict"
                            description="Whether we will ensure this is the exact pattern on the crafting table in the select locations"
                            onChange={(a: string) => setStrictRecipe(a)}
                        />
                    </div>
                </div>
                <div className="w-full aspect-[432/248]">
                    <CraftingTable slotClickCallback={handleSlotClicked} slotData={slotData} />
                </div>

                <div className="flex flex-col border-2 border-mediumpurple rounded-lg w-full">
                    <div className="w-full p-3 bg-purple rounded-t-lg">Component</div>
                    <div className="p-4 bg-lightpurple rounded-b-lg break-words">{JSON.stringify(command)}</div>
                </div>

                <div className="w-full flex justify-end">
                    <div className="flex flex-row flex-wrap gap-4 w-full">
                        <button
                            className="bg-transparent border-2 border-mediumpurple text-mediumpurple p-2 px-7 font-bold rounded-lg w-full"
                            onClick={downloadDatapack}
                        >
                            Download Base Datapack
                        </button>

                        <button
                            className="bg-lightblue text-black p-2 px-7 rounded-lg font-bold w-full"
                            onClick={() => {
                                navigator.clipboard.writeText(JSON.stringify(command));
                            }}
                        >
                            Copy Component
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default App;
