import React, { useState, useEffect, useCallback } from "react";

interface SliderProps {
    value: number;
    label: string;
    onChange: (value: number) => void;
    values: number[];
}

const Slider = ({ value, label, onChange, values }: SliderProps) => {
    const [localValue, setLocalValue] = useState(value);
    const [isDragging, setIsDragging] = useState(false);
    const sliderRef = React.useRef<HTMLDivElement>(null);

    useEffect(() => {
        setLocalValue(value);
    }, [value]);

    const minValue = Math.min(...values);
    const maxValue = Math.max(...values);

    const calculateNewValue = useCallback(
        (clientX: number) => {
            if (!sliderRef.current) return localValue;

            const rect = sliderRef.current.getBoundingClientRect();
            const percentage = Math.max(0, Math.min(1, (clientX - rect.left) / rect.width));
            const newValue = Math.round(minValue + percentage * (maxValue - minValue));
            return Math.max(minValue, Math.min(maxValue, newValue));
        },
        [localValue, minValue, maxValue]
    );

    const updateValue = useCallback(
        (newValue: number) => {
            setLocalValue(newValue);
            onChange(newValue);
        },
        [onChange]
    );

    const handleMove = useCallback(
        (clientX: number) => {
            if (isDragging) {
                const newValue = calculateNewValue(clientX);
                updateValue(newValue);
            }
        },
        [isDragging, calculateNewValue, updateValue]
    );

    const handleMouseDown = useCallback(() => {
        setIsDragging(true);
    }, []);

    const handleMouseMove = useCallback(
        (e: MouseEvent) => {
            handleMove(e.clientX);
        },
        [handleMove]
    );

    const handleMouseUp = useCallback(() => {
        setIsDragging(false);
    }, []);

    const handleTouchStart = useCallback(() => {
        setIsDragging(true);
    }, []);

    const handleTouchMove = useCallback(
        (e: TouchEvent) => {
            e.preventDefault();
            const touch = e.touches[0];
            handleMove(touch.clientX);
        },
        [handleMove]
    );

    const handleTouchEnd = useCallback(() => {
        setIsDragging(false);
    }, []);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = parseInt(event.target.value, 10);
        updateValue(newValue);
    };

    useEffect(() => {
        if (isDragging) {
            window.addEventListener("mousemove", handleMouseMove);
            window.addEventListener("mouseup", handleMouseUp);
            window.addEventListener("touchmove", handleTouchMove, { passive: false });
            window.addEventListener("touchend", handleTouchEnd);
        }

        return () => {
            window.removeEventListener("mousemove", handleMouseMove);
            window.removeEventListener("mouseup", handleMouseUp);
            window.removeEventListener("touchmove", handleTouchMove);
            window.removeEventListener("touchend", handleTouchEnd);
        };
    }, [isDragging, handleMouseMove, handleMouseUp, handleTouchMove, handleTouchEnd]);

    return (
        <div className="w-full min-w-[280px] max-w-xl select-none">
            <div className="flex items-center justify-between mb-3">
                <span className="text-sm font-bold text-gray-400 uppercase">{label}</span>
                <span className="ml-2 text-base font-bold text-white">{localValue}</span>
            </div>
            <div ref={sliderRef} className="relative h-8 flex items-center touch-none">
                <div className="absolute w-full h-4 bg-lightergray rounded-sm">
                    <div
                        className="absolute h-4 bg-[#a39bba] rounded-sm"
                        style={{
                            width: `${((localValue - minValue) / (maxValue - minValue)) * 100}%`,
                        }}
                    />
                </div>
                <input
                    type="range"
                    min={minValue}
                    max={maxValue}
                    value={localValue}
                    onChange={handleInputChange}
                    onMouseDown={handleMouseDown}
                    onTouchStart={handleTouchStart}
                    className="absolute w-full h-8 opacity-0 cursor-pointer z-10"
                />
                <div
                    className={`absolute w-4 h-6 bg-white rounded-md shadow-md -ml-1.5 pointer-events-none transition-shadow duration-200 ${
                        isDragging ? "shadow-lg" : ""
                    }`}
                    style={{
                        left: `${((localValue - minValue) / (maxValue - minValue)) * 100}%`,
                    }}
                />
            </div>
        </div>
    );
};

export default Slider;
