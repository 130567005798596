import React, { useEffect, useRef } from "react";

interface ContextMenuProps {
    x: number;
    y: number;
    onClose: () => void;
    options: ({ label: string; action: () => void } | null)[];
}

const ContextMenu: React.FC<ContextMenuProps> = ({ x, y, onClose, options }) => {
    const menuRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
                onClose();
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [onClose]);

    return (
        <div
            ref={menuRef}
            style={{
                position: "fixed",
                top: y,
                left: x,
                backgroundColor: "#1e1e1e",
                borderRadius: "4px",
                padding: "8px",
                zIndex: 1000,
                boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
            }}
        >
            {options.map(
                (option, index) =>
                    option && (
                        <div
                            key={index}
                            onClick={() => {
                                option.action();
                                onClose();
                            }}
                            style={{ cursor: "pointer", padding: "4px 8px" }}
                        >
                            {option.label}
                        </div>
                    )
            )}
        </div>
    );
};

export default ContextMenu;
