import React, { useState } from "react";
import styled from "styled-components";

interface Props {
    options: { label: string; value: string }[];
    title: string;
    description: string;
    onChange: (value: string) => void;
}

const Bullet = () => (
    <svg viewBox="0 0 6 7" fill="none" xmlns="http://www.w3.org/2000/svg" className="mt-1 w-[7px] h-[7px] flex-shrink-0">
        <path d="M4.5 1.75V0H1.5V1.75H0V5.25H1.5V7H4.5V5.25H6V1.75H4.5Z" fill="#A39BBA" />
    </svg>
);

const Description = styled.p`
    color: #6f678d;
    font-size: 14px;
    margin-bottom: 12px;
`;

const OptionPicker = ({ options, title, description, onChange }: Props) => {
    const [selectedIndex, setSelectedIndex] = useState(0);

    return (
        <div className="w-full flex flex-col">
            <p className="mb-2">{title}</p>
            <div className="flex flex-row w-full gap-2">
                {options.map((option, index) => (
                    <button
                        key={index}
                        onClick={() => {
                            setSelectedIndex(index);
                            onChange(option.value);
                        }}
                        className={`rounded-xl p-3 font-bold w-full ${
                            index === selectedIndex ? "bg-[#00B8FF] text-black" : "border-mediumpurple text-mediumpurple border-2 bg-transparent"
                        }`}
                    >
                        {option.label}
                    </button>
                ))}
            </div>

            <div className="flex flex-row gap-2 mt-2">
                <Bullet />
                <Description>{description}</Description>
            </div>
        </div>
    );
};

export default OptionPicker;
