import React, { useState } from "react";

export interface Item {
    name: string;
    id: string;
    offsetX: number;
    offsetY: number;
}

interface ItemIconProps {
    item: Item;
    size?: number;
    amount?: number;
    alwaysShowCount?: boolean;
    countBottom?: boolean;
}

const ItemIcon = ({ item, size = 64, amount = 1, alwaysShowCount = false, countBottom = false }: ItemIconProps) => {
    const scale = size / 64;

    return (
        <div className={`relative flex flex-col justify-end`} style={{ minWidth: `${size}px`, minHeight: `${size}px` }}>
            <div
                className="absolute z-0"
                style={{
                    backgroundImage: `url('https://raw.githubusercontent.com/K9Developer/MinecraftData/refs/heads/main/items2/atlas2.png')`,
                    backgroundPosition: `-${(item?.offsetX ?? 0) * scale}px -${(item?.offsetY ?? 0) * scale}px`,
                    backgroundSize: `${2432 * scale}px ${2496 * scale}px`,
                    backgroundRepeat: "no-repeat",
                    imageRendering: "pixelated",
                    width: `${size}px`,
                    height: `${size}px`,
                    opacity: item?.offsetX !== undefined ? 1 : 0,
                    marginBottom: countBottom ? `${size / 2}px` : 0,
                }}
            />

            {((!alwaysShowCount && amount > 1) || alwaysShowCount) && (
                <div
                    className={`text-white text-[${scale}vw] rounded-lg font-bold z-10 text-right ${
                        countBottom ? "" : "ml-8"
                    } mt-2 stroke-black stroke-2`}
                    style={{ fontFamily: "minecraftFont" }}
                >
                    {amount}
                </div>
            )}
        </div>
    );
};

export default ItemIcon;
