import React, { useState, useRef } from "react";

const UploadIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12 text-white">
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5"
        />
    </svg>
);

const ServerIconUploader = ({ serverIcon, setServerIcon }: { serverIcon: string; setServerIcon: React.Dispatch<React.SetStateAction<string>> }) => {
    const [isHovered, setIsHovered] = useState(false);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return;
        const file = event.target.files[0];
        if (file && file.type.startsWith("image/")) {
            const reader = new FileReader();
            reader.onload = (e: ProgressEvent<FileReader>) => {
                const img = new Image();
                img.onload = () => {
                    const canvas = document.createElement("canvas");
                    const ctx = canvas.getContext("2d");
                    canvas.width = canvas.height = Math.min(img.width, img.height);
                    ctx?.drawImage(
                        img,
                        (img.width - canvas.width) / 2,
                        (img.height - canvas.height) / 2,
                        canvas.width,
                        canvas.height,
                        0,
                        0,
                        canvas.width,
                        canvas.height
                    );
                    setServerIcon(canvas.toDataURL());
                };
                if (!e.target?.result) return;
                img.src = e.target.result as string;
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <div
            className="relative cursor-pointer h-full aspect-square ml-[2%] rounded-lg overflow-hidden"
            style={{ height: "80%" }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={() => fileInputRef.current?.click()}
        >
            <img src={serverIcon} alt="" className="w-full h-full top-0 object-cover rounded-lg" />
            <div
                className="absolute inset-0 bg-black transition-opacity duration-300 flex items-center justify-center w-full h-full"
                style={{ opacity: isHovered ? 0.7 : 0 }}
            >
                <UploadIcon />
            </div>
            <input ref={fileInputRef} type="file" accept="image/*" className="hidden" onChange={handleFileChange} />
        </div>
    );
};

export default ServerIconUploader;
