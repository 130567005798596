import React, { useEffect } from "react";
import ItemIcon from "../components/ItemIcon";
import Tooltip from "../components/Tooltip";
import Modal from "../components/Modal";
import SelectedItemsRow from "../components/SelectedItemsRow";

interface Props {
    itemAssetData: any;
    crafts: any;
    pageTwoCallback: (itemsToCraft: any) => void;
}

const PageOne = ({ itemAssetData, crafts, pageTwoCallback }: Props) => {
    const [search, setSearch] = React.useState("");
    const [selectedItem, setSelectedItem] = React.useState<any | null>(null);
    const [showChooseAmount, setShowChooseAmount] = React.useState(false);
    const itemCountInputRef = React.useRef<HTMLInputElement>(null);
    const [itemCount, setItemCount] = React.useState(0);
    const [itemsToCraft, setItemsToCraft] = React.useState<any>({});
    return (
        <div className="flex w-full justify-center flex-col items-center">
            <Modal
                isOpen={showChooseAmount}
                title={`How many ${selectedItem?.name ?? "n/a"}(s) do you need?`}
                onClose={() => {
                    setShowChooseAmount(false);
                    setItemCount(0);
                    setSelectedItem(null);
                }}
            >
                <input
                    ref={itemCountInputRef}
                    type="number"
                    pattern="^[0-9]+$"
                    className="w-full p-2 px-4 rounded-md bg-lightpurple"
                    inputMode="numeric"
                    onChange={async (e) => {
                        setTimeout(() => {
                            setItemCount(parseInt(e.target.value || "0"));
                        }, 0);
                    }}
                />

                <p className="mt-3 text-slate-500">
                    That's {itemCount / 64 !== Math.floor(itemCount / 64) ? "about " : ""}
                    {Math.round((itemCount / 64 + Number.EPSILON) * 100) / 100} stack(s)
                </p>

                <div className="mt-4 flex flex-row gap-2 justify-end">
                    <button
                        className="bg-lightblue text-black font-semibold"
                        disabled={itemCount === 0}
                        onClick={() => {
                            setItemsToCraft((prev: any) => {
                                return {
                                    ...prev,
                                    [selectedItem.id]: {
                                        count: itemCount,
                                        item: selectedItem,
                                    },
                                };
                            });
                            setShowChooseAmount(false);
                            setItemCount(0);
                            setSelectedItem(null);
                        }}
                    >
                        Add
                    </button>
                    <button
                        className="text-darkgray bg-transparent !border-2 border-darkgray font-semibold"
                        onClick={() => {
                            setShowChooseAmount(false);
                            setItemCount(0);
                            setSelectedItem(null);
                        }}
                    >
                        Cancel
                    </button>
                </div>
            </Modal>
            <div className=" flex justify-center flex-col items-center">
                <div className="flex flex-row w-full mb-5 gap-2">
                    <input
                        type="text"
                        className="w-full px-7 py-1 rounded-lg bg-lightpurple"
                        placeholder="Search..."
                        onChange={(e) => setSearch(e.target.value)}
                    />
                    <button
                        title={Object.keys(itemsToCraft).length ? "Get Crafts" : "Select items to get crafts"}
                        className={Object.keys(itemsToCraft).length ? "bg-[#00B8FF] text-black font-semibold text-nowrap" : ""}
                        onClick={() => {
                            const newItemsToCraft: { [key: string]: number } = {};
                            for (const item in itemsToCraft) {
                                newItemsToCraft[item] = itemsToCraft[item].count;
                            }
                            pageTwoCallback(newItemsToCraft);
                        }}
                        disabled={!Object.keys(itemsToCraft).length}
                    >
                        Get Crafts
                    </button>
                </div>
                <SelectedItemsRow
                    items={itemsToCraft}
                    onDelete={(itemId: string) =>
                        setItemsToCraft((prev: any) => {
                            const newItems = { ...prev };
                            delete newItems[itemId];
                            return newItems;
                        })
                    }
                    onChange={(itemId: string, newCount: number) => {
                        setItemsToCraft((prev: any) => {
                            return { ...prev, [itemId]: { count: newCount, item: prev[itemId].item } };
                        });
                    }}
                />
                <div className="flex flex-row flex-wrap gap-2 w-fit">
                    {itemAssetData.map(
                        (item: any) =>
                            crafts["minecraft:" + item.id] &&
                            item.name.toLowerCase().includes(search.toLowerCase() ?? "") && (
                                <div
                                    className="mx-auto cursor-pointer"
                                    id={item.id}
                                    onClick={() => {
                                        setSelectedItem(item);
                                        setShowChooseAmount(true);
                                    }}
                                >
                                    <Tooltip content={item.name} key={item.id + "tt"}>
                                        <ItemIcon item={item} size={40} key={item.id + "icon"} />
                                    </Tooltip>
                                </div>
                            )
                    )}
                </div>
            </div>
        </div>
    );
};

export default PageOne;
