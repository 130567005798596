import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import tw from "twin.macro";

interface ModalOverlayProps {
    visible?: boolean;
}

const ModalOverlay = styled.div<ModalOverlayProps>`
    ${tw`fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50`}
    transition: backdrop-filter 0.2s ease-in-out;
    backdrop-filter: ${(props) => (props.visible ? "blur(5px)" : "blur(0px)")};
`;

const ModalContent = styled.div<ModalOverlayProps>`
    ${tw`bg-purple p-6 rounded-lg shadow-xl max-w-lg w-full max-h-[80vh] overflow-y-auto m-2`}
    transition: opacity 0.2s ease-in-out;
    opacity: ${(props) => (props.visible ? 1 : 0)};
`;

interface Params {
    isOpen: boolean;
    onClose: () => void;
    title: string;
    children: React.ReactNode;
}

const Modal = ({ isOpen, onClose, title, children }: Params) => {
    const [visible, setVisible] = useState(false);
    const overlayRef = useRef(null);
    const contentRef = useRef(null);
    const mouseDownOnOverlayRef = useRef(false);

    const handleCloseWithTransition = () => {
        setVisible(false);
        setTimeout(onClose, 200); // Wait for the transition to complete before closing
    };

    useEffect(() => {
        const handleEscape = (event: KeyboardEvent) => {
            if (event.key === "Escape") {
                handleCloseWithTransition();
            }
        };

        if (isOpen) {
            document.addEventListener("keydown", handleEscape);
            document.body.style.overflow = "hidden";
            setTimeout(() => setVisible(true), 20);
        } else {
            setVisible(false);
        }

        return () => {
            document.removeEventListener("keydown", handleEscape);
            document.body.style.overflow = "visible";
        };
    }, [isOpen]);

    useEffect(() => {
        const handleMouseDown = (e: MouseEvent) => {
            mouseDownOnOverlayRef.current = e.target === overlayRef.current;
        };

        const handleMouseUp = (e: MouseEvent) => {
            if (mouseDownOnOverlayRef.current && e.target === overlayRef.current) {
                handleCloseWithTransition();
            }
            mouseDownOnOverlayRef.current = false;
        };

        document.addEventListener("mousedown", handleMouseDown);
        document.addEventListener("mouseup", handleMouseUp);

        return () => {
            document.removeEventListener("mousedown", handleMouseDown);
            document.removeEventListener("mouseup", handleMouseUp);
        };
    }, []);

    if (!isOpen) return null;

    return (
        <ModalOverlay ref={overlayRef} visible={visible}>
            <ModalContent ref={contentRef} visible={visible} onClick={(e) => e.stopPropagation()}>
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-xl font-bold">{title}</h2>
                    <button
                        className="bg-transparent border-none text-xl cursor-pointer text-gray-500 hover:text-gray-700"
                        onClick={handleCloseWithTransition}
                    >
                        &times;
                    </button>
                </div>
                <div className="text-base leading-relaxed">{children}</div>
            </ModalContent>
        </ModalOverlay>
    );
};

export default Modal;
