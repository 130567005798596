import { getTagType, TAG, ListTag, Int8, Int16, Int32, Float32 } from "nbtify";
import React, { useEffect, useState } from "react";
import Branch from "./Branch";

interface Props {
    data: any;
    onChange: (data: any) => void;
    currentResult: string[];
}

const NBTTree = ({ data, onChange, currentResult }: Props) => {
    const [nbtTreeData, setNBTTreeData] = useState(data.data);
    const [copied, setCopied] = useState(null);

    useEffect(() => {
        setNBTTreeData(data.data);
    }, [data]);

    const handleBranchUpdate = (path: string[], newKey: string, newValue: any, action = "update", type = TAG.END) => {
        const createTag = (type: TAG, value: any) => {
            switch (type) {
                case TAG.BYTE:
                    return new Int8(parseInt(value));
                case TAG.SHORT:
                    return new Int16(parseInt(value));
                case TAG.INT:
                    return new Int32(parseInt(value));
                case TAG.LONG:
                    return BigInt(value);
                case TAG.FLOAT:
                    return new Float32(parseFloat(value));
                case TAG.DOUBLE:
                    return Number(parseInt(value));
                case TAG.STRING:
                    return value as string;
                case TAG.BYTE_ARRAY:
                    return new Int8Array([]);
                case TAG.INT_ARRAY:
                    return new Int32Array([]);
                case TAG.LONG_ARRAY:
                    return new BigInt64Array([]);
                case TAG.LIST:
                    return [];
                case TAG.COMPOUND:
                    return value;
                default:
                    return value as string;
            }
        };

        const updateObject = (obj: any, keys: string[], index: number): any => {
            const currentKey = keys[index];

            if (index === keys.length - 1) {
                if (action === "delete") {
                    const { [currentKey]: _, ...rest } = obj;
                    return rest;
                }

                const currentType = getTagType(obj[currentKey]);
                switch (action) {
                    case "update":
                        return { ...obj, [newKey]: createTag(type, newValue) };
                    case "add":
                        if (currentType === TAG.COMPOUND) {
                            return {
                                ...obj,
                                [currentKey]: {
                                    ...obj[currentKey],
                                    [newKey]: createTag(type, newValue),
                                },
                            };
                        } else if (currentType === TAG.BYTE_ARRAY || currentType === TAG.INT_ARRAY || currentType === TAG.LONG_ARRAY) {
                            return {
                                ...obj,
                                [currentKey]: new obj[currentKey].constructor([...obj[currentKey], newValue]),
                            };
                        } else if (currentType === TAG.LIST) {
                            return {
                                ...obj,
                                [currentKey]: [...obj[currentKey], createTag(type, newValue)] as ListTag<any>,
                            };
                        }
                        break;
                    default:
                        return obj;
                }
            }

            return {
                ...obj,
                [currentKey]: updateObject(obj[currentKey], keys, index + 1),
            };
        };

        const newObj = updateObject(nbtTreeData, path, 0);
        console.log(newObj);
        setNBTTreeData(newObj);
        onChange(newObj);
    };

    return (
        <div className="flex flex-row ml-6 mt-10">
            {nbtTreeData && Object.keys(nbtTreeData).length > 0 ? (
                <div className="flex flex-col gap-2">
                    {Object.keys(nbtTreeData).map((tag) => (
                        <Branch
                            key={Math.random()}
                            label={tag}
                            type={Number(getTagType(nbtTreeData[tag]))}
                            parentType={-1}
                            onUpdate={handleBranchUpdate}
                            currentPath={[tag]}
                            children={nbtTreeData[tag]}
                            depth={1}
                            currentResult={currentResult}
                            copied={copied}
                            setCopied={setCopied}
                        />
                    ))}
                </div>
            ) : (
                <div className="w-full h-full flex flex-col justify-center items-center gap-3">
                    <p>Seems like the NBT file is empty or invalid</p>
                    <button
                        onClick={() => {
                            setNBTTreeData({ Data: {} });
                        }}
                    >
                        Add Tag
                    </button>
                </div>
            )}
        </div>
    );
};

export default NBTTree;
