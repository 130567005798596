import React from "react";
import NumberInput from "./NumberInput";

const UpIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="size-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
    </svg>
);

const DownIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="size-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
    </svg>
);

const TimeField = ({ label, onChange, value }: { label: string; onChange: (v: any, type: string) => any; value: number }) => {
    return (
        <div className="flex flex-col w-full gap-2">
            <label className="mr-2 w-[40%] inline-block h-fit text-lightergray">{label}</label>
            <div className="relative">
                <NumberInput
                    onChange={(v, type) => {
                        if (v < 0) return;
                        onChange(v, type);
                    }}
                    value={value}
                    allowMinus={false}
                />
            </div>
        </div>
    );
};

export default TimeField;
