

// Accepts a color prop
const TopGradient = ({ color }: { color: string }) => {
    return (
        <svg width="208" height="212" viewBox="0 0 208 212" fill="none" xmlns="http://www.w3.org/2000/svg" className="absolute z-0 top-0 left-0">
            <path d="M207.25 196C207.25 204.422 200.422 211.25 192 211.25L16 211.25C7.57768 211.25 0.75003 204.422 0.750029 196L0.75001 16C0.750009 7.57767 7.57766 0.750015 16 0.750014L192 0.750001C200.422 0.750001 207.25 7.57765 207.25 16L207.25 196Z" stroke="url(#paint0_linear_256_1304)" stroke-opacity="0.7" stroke-width="2" />
            <defs>
                <linearGradient id="paint0_linear_256_1304" x1="6.48105" y1="16.9202" x2="67.3352" y2="46.1316" gradientUnits="userSpaceOnUse">
                    <stop stop-color={color} />
                    <stop offset="1" stop-color={color} stop-opacity="0" />
                </linearGradient>
            </defs>
        </svg>
    );
}

export default TopGradient;