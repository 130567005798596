import { useEffect, useRef, useState } from "react";
import ReactSkinview3d from "react-skinview3d";
import { IdleAnimation } from "skinview3d";
import Panorama from "./assets/panorama.png";
import Spinner from "./components/Spinner";
import Page from "../../components/Page";

function App() {
    const [skinUrl, setSkinUrl] = useState<string | null>(null);
    const [capeUrl, setCapeUrl] = useState<string | null>(null);
    const [playerName, setPlayerName] = useState<string>("");
    const [headIconUrl, setHeadIconUrl] = useState<string>("");
    const playerInputRef = useRef<HTMLInputElement>(null);
    const [showBadName, setShowBadName] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [viewWidth, setViewWidth] = useState(500);

    useEffect(() => {
        const onResize = () => {
            setViewWidth(window.innerWidth > 900 ? 500 : window.innerWidth * 0.80);
        };

        window.addEventListener("resize", onResize);
        onResize();
        return () => window.removeEventListener("resize", onResize);
    }, []);

    const skinViewConfig = {
        panorama: Panorama,
        zoom: 0.7,
        fov: 90,
        speed: 0.1,
        animation: new IdleAnimation(),
    };

    const onViewSkin = async () => {
        if (!playerInputRef.current) return;
        setIsLoading(true);
        try {
            // get player
            const playerProfile = await fetch(`https://api.minetools.eu/uuid/${playerInputRef.current.value}`);
            const playerProfileJson = await playerProfile.json();
            if (playerProfileJson.status === "ERR") {
                setIsLoading(false);
                setShowBadName(true);
                return;
            }

            // get data
            const playerData = await fetch(`https://api.minetools.eu/profile/${playerProfileJson.id}`);
            const playerDataJson = await playerData.json();
            if (playerDataJson.decoded === null) {
                setIsLoading(false);
                setShowBadName(true);
                return;
            }

            setPlayerName(playerDataJson.decoded.profileName);
            setSkinUrl(`https://crafatar.com/skins/${playerProfileJson.id}`);
            setHeadIconUrl(`https://crafatar.com/avatars/${playerProfileJson.id}?overlay`);
            try {
                const capeData = await fetch(`https://crafatar.com/capes/${playerProfileJson.id}`);
                if (capeData.status !== 404) setCapeUrl(`https://crafatar.com/capes/${playerProfileJson.id}`);
                else setCapeUrl(null);
            } catch (error) {
                console.error(error);
                setCapeUrl(null);
            }

            setIsLoading(false);
            setShowBadName(false);
        } catch (error) {
            console.error(error);
            setIsLoading(false);
            setShowBadName(true);
        }
    };

    return (
        <Page toolName="skin_grabber">
            <div className="flex flex-col items-center justify-center">
                <div
                    className="flex flex-col md:flex-row justify-between gap-3 mb-5 w-full"
                    style={{
                        width: viewWidth,
                    }}
                >
                    <input type="text" className="rounded-md p-3 py-2 bg-lightpurple w-full" placeholder="Enter a username / uuid" ref={playerInputRef} />
                    <button onClick={onViewSkin} className="bg-lightblue text-black font-semibold text-nowrap">
                        View Skin
                    </button>
                </div>
                {showBadName && playerInputRef.current && <p className="text-red-500 mb-2">This player does not exist!</p>}
                {skinUrl && !showBadName ? (
                    <ReactSkinview3d
                        skinUrl={skinUrl ?? ""}
                        capeUrl={capeUrl ?? ""}
                        width={viewWidth}
                        height={viewWidth}
                        options={skinViewConfig}
                        className="rounded-t-2xl cursor-move border-2 border-mediumpurple"
                    />
                ) : (
                    <div
                        className="bg-lightpurple rounded-t-2xl border-2 border-mediumpurple"
                        style={{
                            width: viewWidth,
                            height: viewWidth,
                        }}
                    />
                )}

                <div
                    className="flex flex-row gap-3 items-center bg-lightpurple h-16 px-5 border-2 border-mediumpurple rounded-b-2xl border-t-0 w-full justify-between"
                    style={{ width: viewWidth }}
                >
                    {!showBadName && skinUrl && headIconUrl && (
                        <>
                            <div className="flex flex-row items-center gap-3">
                                <img hidden={!headIconUrl} src={headIconUrl} alt="" className="w-10 aspect-square rounded-full" />
                                <p className="h-fit text-lg">{playerName}</p>
                            </div>
                            <div className="flex flex-row items-center gap-3">
                                <button
                                    className="border-mediumpurple border-2 bg-transparent"
                                    onClick={() => {
                                        fetch(skinUrl)
                                            .then((response) => response.blob())
                                            .then((blob) => {
                                                const url = window.URL.createObjectURL(blob);
                                                const a = document.createElement("a");
                                                a.href = url;
                                                a.download = playerName + ".png";
                                                document.body.appendChild(a);
                                                a.click();
                                                window.URL.revokeObjectURL(url);
                                                document.body.removeChild(a);
                                            })
                                            .catch((error) => {
                                                console.error("Download failed:", error);
                                            });
                                    }}
                                >
                                    Download
                                </button>
                            </div>
                        </>
                    )}
                </div>
            </div>
            {isLoading && <Spinner />}
        </Page>
    );
}

export default App;
