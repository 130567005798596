import React from "react"
import { tools } from "../tools";
import TopGradient from "./TopGradient";
import YT from "../assets/images/yt.png";
import BackgroundImage from "../assets/images/tools-bg.png";
import AD from "../assets/images/ad.png";

interface Props {
    children: React.ReactNode;
    toolName: string;
}

export default function Page({ children, toolName }: Props) {
    const tool = tools.find((t) => t.key === toolName);
    if (!tool) return null;
    
    return (
        <>
            <img src={BackgroundImage} alt="Background" className="fixed top-0 left-0 w-full h-full object-cover z-[-1]" />
            
            <img src={AD} alt="Advertisement" className="mx-auto max-w-[720px] my-24" />
            <div className="container xl:max-w-[1280px] mx-auto px-4 flex flex-col md:grid md:grid-cols-3 gap-8 mt-4">
                <div className="col-span-1 bg-purple rounded-2xl overflow-hidden h-full max-h-[550px]">
                    <div className="bg-purple bg-opacity-80 relative p-6 h-full">
                        <TopGradient color={tool.color} />
                        <img src={tool.image} alt={tool.title} className="w-36 h-32 left-2 bottom-0 absolute blur-[18px] opacity-70 pointer-events-none" />
                        <div className="flex flex-col h-full justify-between">
                            <div className="flex flex-col gap-6">

                                <img src={tool.image} alt={tool.title} className="h-10 w-fit" />
                                <h5 className="text-white font-semibold text-lg">{tool.title}</h5>
                                <p className="text-[#ACA9B4] text-sm">{tool.description}</p>
                            </div>
                            <div className="pt-20">
                                <div className="flex flex-row gap-2">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M17 18.4301H13L8.54999 21.39C7.88999 21.83 7 21.3601 7 20.5601V18.4301C4 18.4301 2 16.4301 2 13.4301V7.42999C2 4.42999 4 2.42999 7 2.42999H17C20 2.42999 22 4.42999 22 7.42999V13.4301C22 16.4301 20 18.4301 17 18.4301Z" stroke="#00B8FF" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M12.0001 11.36V11.15C12.0001 10.47 12.4201 10.11 12.8401 9.82001C13.2501 9.54001 13.66 9.18002 13.66 8.52002C13.66 7.60002 12.9201 6.85999 12.0001 6.85999C11.0801 6.85999 10.3401 7.60002 10.3401 8.52002" stroke="#00B8FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M11.9955 13.75H12.0045" stroke="#00B8FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>

                                    <p className="font-semibold">
                                        Watch tutorial
                                    </p>
                                </div>
                                <img src={YT} alt="YouTube" className="rounded-xl my-2 z-10 relative" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-span-2 flex flex-col bg-purple p-7 w-full rounded-2xl">
                    <div className="flex flex-row justify-between mb-6 items-center">
                        <h4 className="font-semibold text-lg">{tool.title}</h4>
                        <p className="text-lightergray text-sm">{tool.version}</p>
                    </div>
                    {children}
                </div>
            </div>
        </>
    )
}