import { useState } from "react";
import TimeField from "./components/TimeField";
import Button from "./components/Button";
import AnimatedButton from "./components/AnimatedButton";
import Page from "../../components/Page";

const ResetIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
        />
    </svg>
);

function App() {
    const [ticks, setTicks] = useState(0);
    const [timeData, setTimeData] = useState<{ [key: string]: number }>({ seconds: 0, minutes: 0, hours: 0, days: 0 });

    const calculateTicks = (data: { [key: string]: number }) => {
        const { seconds, minutes, hours, days } = data;
        const totalSeconds = seconds + minutes * 60 + hours * 3600 + days * 86400;
        setTicks(totalSeconds * 20);
    };

    const timeChanged = (type: string, value: number, t: string) => {
        if (isNaN(value)) return;

        let newData;
        if (t === "blur") {
            let totalSeconds = timeData.seconds + timeData.minutes * 60 + timeData.hours * 3600 + timeData.days * 86400;
            totalSeconds -= timeData[type] * (type === "seconds" ? 1 : type === "minutes" ? 60 : type === "hours" ? 3600 : 86400);
            totalSeconds += value * (type === "seconds" ? 1 : type === "minutes" ? 60 : type === "hours" ? 3600 : 86400);

            const days = Math.floor(totalSeconds / 86400);
            totalSeconds %= 86400;
            const hours = Math.floor(totalSeconds / 3600);
            totalSeconds %= 3600;
            const minutes = Math.floor(totalSeconds / 60);
            const seconds = totalSeconds % 60;
            newData = { seconds, minutes, hours, days };
        } else {
            newData = { ...timeData, [type]: value };
        }
        setTimeData(newData);
        calculateTicks(newData);
    };

    return (
        <Page toolName="tick_calc">
            <div className="px-12 py-2 pb-10 flex flex-col justify-center items-center gap-7 max-w-[520px] mx-auto">
                <div className="flex flex-col gap-3 w-full">
                    <TimeField value={timeData.seconds} label="Seconds" onChange={(v, type) => timeChanged("seconds", v, type)} />
                    <TimeField value={timeData.minutes} label="Minutes" onChange={(v, type) => timeChanged("minutes", v, type)} />
                    <TimeField value={timeData.hours} label="Hours" onChange={(v, type) => timeChanged("hours", v, type)} />
                    <TimeField value={timeData.days} label="Days" onChange={(v, type) => timeChanged("days", v, type)} />
                </div>
                <div className="w-full flex flex-row justify-between flex-wrap gap-2">
                    <div className="flex flex-col gap-1">
                        <p className="text-[#A39BBA] font-extralight">TICKS:</p>
                        <p className="font-semibold">{ticks}</p>
                    </div>

                    <div className="flex flex-row gap-2">
                        <AnimatedButton
                            action={() => {
                                navigator.clipboard.writeText(`/tickstep ${ticks}`);
                            }}
                            copiedText="Copied!"
                            text="Copy Tick Command"
                            filled
                        />
                        <Button
                            color="grey"
                            onClick={() => {
                                setTimeData({ seconds: 0, minutes: 0, hours: 0, days: 0 });
                                setTicks(0);
                            }}
                        >
                            <div className="flex flex-row gap-1">
                                Clear
                                <ResetIcon />
                            </div>
                        </Button>
                    </div>
                </div>

                <div className="text-[#A39BBA] flex flex-row gap-2">
                    <svg width="20" height="20" viewBox="0 0 6 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.5 1.75V0H1.5V1.75H0V5.25H1.5V7H4.5V5.25H6V1.75H4.5Z" fill="#A39BBA" />
                    </svg>
                    <span className="text-sm">
                        The command “/tickstep VALUE” only works when you have frozen the ticking first! You should do that with the command “/tick
                        stop”. Using the “/tick step” command will unfreeze the ticks automatically.
                    </span>
                </div>
            </div>
            </Page>
    );
}

export default App;
