import { ReactComponent as Logo } from "../assets/images/logo.svg";
import { tools, Tool } from "../tools";

const NavigationBar = () => {
    return (
        <div className="bg-lightpurple h-[72px] px-6 flex overflow-auto items-center gap-8">
            <a href="/" className="mr-4">
                <Logo />
            </a>
            {tools && tools.map((tool, index) => <NavigationItem key={index} tool={tool} />)}
        </div>
    );
};

const NavigationItem = ({ tool }: { tool: Tool }) => {
    return (
        <a className="flex gap-2 items-center" href={tool.href}>
            <img src={tool.image} alt={tool.title} className="h-6" />
            <p className="text-white">{tool.title}</p>
        </a>
    );
};

export default NavigationBar;
